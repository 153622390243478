<template>
  <div class="login">
    <v-container>
      <v-row>
        <v-col cols="1" xs="1" sm="2" md="3" lg="4"></v-col>
        <v-col cols="10" xs="10" sm="8" md="6" lg="4">


          <h2 class="grey--text mt-6">Anmeldung</h2>
          <br />
          <v-alert
            border="left"
            colored-border
            type="error"
            elevation="15"
            v-if="service_announcement">
            Am <b>Mittwoch, 28.09.22</b> ist dieser Dienst wegen Wartungsarbeiten nicht erreichbar.
            Wir bitten, etwaige Unannehmlichkeiten zu entschuldigen und danken für Ihr Verständnis.
          </v-alert>

          <v-alert
            border="left"
            colored-border
            type="error"
            elevation="15"
            v-if="service_ongoing">
            <h3>Wartungsarbeiten</h3>
            Die Wartungsarbeiten werden voraussichtlich am <b>Donnerstag, 29.09.22</b> abgeschlossen sein.
          </v-alert>

          <template v-if="!service_ongoing">
            <v-form 
              ref="loginform"
              class="login-form my-6"
              v-model="valid"
              lazy-validation
            >
              <v-text-field outlined 
                v-model="username"
                name="Benutznername"
                label="Benutzername"
                :rules="[rules.required, rules.min]"
              />

              <v-text-field outlined
                v-model="password"
                :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPw ? 'text' : 'password'"
                name="input-10-1"
                label="Ihr Password"
                hint="Mindestens 8 Zeichen"
                counter
                @click:append="showPw = !showPw"
              ></v-text-field>

              <v-btn
                color="success"
                :disabled="!valid"
                @click="validate"
              >anmelden</v-btn>

            </v-form>
            <div class="pw-reset-link">
              Sind Sie zum ersten Mal hier, oder haben Sie Ihr Password vergessen? <br />
              Hier können Sie Ihr Password (zurück) setzen:<br />
              <a href="/@@login-help" target="_blank">--> Password (zurück) setzen</a><br />
              Sie erhalten dann eine E-Mail mit weiteren Anweisungen.
            </div>
          </template>

        </v-col>
        <v-col cols="1" xs="1" sm="2" md="3" lg="4">
        </v-col>
      </v-row>
    </v-container>

    <!-- Message Box - - - - - - - - - -->
    <div id="portal-message"
      @mouseleave="showMessage = false"
      @click="showMessage = false"
      class="ma-0 pa-0">
      <v-alert 
        class="ma-0"
        type="info"
        v-if="message && showMessage">
        {{ message }}</v-alert>
    </div>


  </div>
</template>




<script>

import axios from 'axios'
import { 
  zozLoginUrl, 
  zozUsersUrl,
  zozDB,
} from '@/config'

zozDB.config.debug = false


export default {
  name: 'Login',


  data () {
    return {
      username: '',
      password: '',
      token: '',
      loader: null,
      loading: false,
      showPw: false,
      valid: true,
      rules: {
          required: value => !!value || 'Erforderlich.',
          min: v => v.length >= 6 || 'Mind. 6 Zeichen',
      },
      message: '',
      showMessage: false,
      // ======= WARTUNGSARBEITEN angekündigt =========
      service_announcement: false,
      service_ongoing: false,
    }
  },


  mounted: function () {
    // if already logged in, then logout
    if ( typeof this.$cookie != 'undefined') {
      if (this.$cookie.get('__ac')) {
        this.$cookie.delete('__ac')
      } 
    }
  },


  methods: {
    validate () {
      // let cookie = this.$cookie
      if (this.$refs.loginform.validate()) {
        // fetch JWT from Plone
        axios({
          method: 'post',
          url: zozLoginUrl,
          headers: {
            'Accept': 'application/json', 
            'Content-Type': 'application/json'
          },
          data: {
            'login': this.username,
            'password': this.password
          }
        })
        .then(response => {
          if (response.data.token) {
            // --- login erfolgreich ! ---
            this.token = response.data.token

            // --- lese Benutzerdaten aus Plone ---
            axios(zozUsersUrl + this.username, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.token
              }
            })
            .then(resp => {
              this.$cookie.set('__ac', this.token, { expires: '', path: '/' })

              if (this.$cookie.get('__ac')) {
                this.message = "Sie sind jetzt angemeldet."
                this.showMessage = true

                // -- Benutzerdaten in lokale Datenbank ---
                zozDB.collection('user').add({
                  'fullname': resp.data.fullname,
                  'email': resp.data.email,
                  'username': this.username
                }, this.username)

                this.$router.push({'name': 'Zentren'})
              } else {
                alert('\
Anmelden ist zur Zeit nicht möglich. \
Dies kann mehrere Ursachen haben: \n\n\
- Ihr Browser erlaubt keine Cookies. \n\
- Sie haben zur Zeit keine stabile Internet-Verbindung. \n\
- Die Verbindung zum App-Server ist gestört \n\
\n\
Bitte löschen Sie Ihren Browser-Cache und versuchen Sie es zu einem späteren Zeitpunkt erneut.')
              }

            })
            .catch(e => {
              alert(e)
            })
            
          } else {
            alert('Die Anmeldung ist fehlgeschlagen. Bitte überprüfen Sie Ihren Benutzernamen und Ihr Passwort.')
          }
        })
        .catch(e => {
          console.debug(e)
          this.message = "Falscher Benutzername oder falsches Password!"
          this.showMessage = true
        })
        
      } else {
        console.log("Falsche Login-Daten eingegeben.")
      }
      
    }
  },
  watch: {}
}
</script>






<style scope>
.login-form {
  max-width: 460px !important;
}
.v-input__slot {
    background-color: white !important;
}
.pw-reset-link {
  font-size: 96%;
  color: #454545;
  margin-top: 50px;
}
</style>
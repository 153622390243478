<template>
<!-- App.vue -->
  <v-app>
    <link v-if="serverMode === 'STAGING'" rel="stylesheet" href="./staging.css"> 

    <!-- APP HEADER (TOP) -->
    <v-app-bar app dark dense flat color="#234">
      <!--
      <v-app-bar-nav-icon @click="drawer = !drawer">
      </v-app-bar-nav-icon>
    -->

      <v-toolbar-title class="d-none d-sm-flex">
        <img src="@/assets/zoz2-logo-invert-small-300.png" alt="Onkologie Zertifizierung" class="" width="50%"/>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon 
        v-if="onLine" 
        color="lime accent-3">
        mdi-lan-connect
      </v-icon>
      <v-icon 
        v-else
        class="warn">
        mdi-lan-disconnect
      </v-icon>

      <v-spacer></v-spacer>

      <!-- 
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      -->

      <v-tooltip bottom v-if="loggedIn">
        <template 
          v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on">
            mdi-account
          </v-icon>
          <div
            class="fullname d-none d-sm-flex" 
            dense
          >
            {{ userFullname }} ({{ username }})
          </div>
        </template>
        <span>{{ userFullname }}</span>
      </v-tooltip>

      <v-btn icon 
        v-if="loggedIn" 
        @click="logout"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>

      <v-btn icon 
        v-if="!loggedIn" 
        @click="login"
      >
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <router-view>
      <!-- ________________ CONTENT __________________ -->
      </router-view>
    </v-main>

    <v-footer app dense dark>
      <Footer/> 
    </v-footer>


      <!-- Message Box - - - - - - - - - -->
      
      <div id="portal-message"
        @mouseleave="showMessage = false"
        @click="showMessage = false"
        class="center-message ma-0 pa-0">
        <v-alert 
          class="ma-1 pa-8"
          type="info"
          color="light-green darken-1"
          dark
          icon="mdi-checkbox-marked"
          v-if="message && showMessage">
          {{ message }}
        </v-alert>
      </div>

  </v-app>

</template>

<script>

import Vue from 'vue'
import { router, navigationItems } from './router'
// import Localbase from 'localbase'


import { 
  zozBaseUrl, 
  localbaseName,
  zozDB, 
  serverMode } from '@/config'

import Footer from '@/components/Footer'

// const zozDB = new Localbase(localbaseName)

console.debug('################## BASE URL: ' + zozBaseUrl + ' | Mode: ' + serverMode + ' | Localbase: ' + localbaseName + ' #############')


export default {
  name: 'App',

  components: {
    Footer
  },

  data () {
    return {
      drawer: false,
      loggedIn: false,
      username: '',
      userFullname: '',
      userEmail: '',
      selectedItem: 1,
      items: navigationItems,
      message: '',
      showMessage: false,
      onLine: navigator.onLine,
      serverMode: serverMode,
    }
  },

  computed: {},


  watch: {
    showMessage(v) {
      if(v) {
        setTimeout(() => {
          this.showMessage = false
        }, 2000)
      }
    }
  },


  mounted() {
    // --- online/offline event handler -----
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)

    // -- get user from localbase ---
    zozDB.collection('user').get()
    .then(user => {
      if(user && user.length > 0) {
        this.username = user[0].username
        this.userFullname = user[0].fullname
        this.userEmail = user[0].email
      }
    })
    .catch(e => {
      alert(e)
    })

  },


  updated: function () {
    if (Vue.cookie.get('__ac')) {
      this.loggedIn = true
    } else {
      this.loggedIn = false
    }
    // -- get user from localbase ---
    zozDB.collection('user').get()
    .then(user => {
      if(user && user.length > 0) {
        this.username = user[0].username
        this.userFullname = user[0].fullname
        this.userEmail = user[0].email
      }
    })
    .catch(e => {
      alert(e)
    })
  },


  methods: {

    /* -- abmelden -- */
    logout: function () {

      // --- logout nur möglich, wenn online!
      if (!this.onLine) {
        alert("Sie sind offline, haben keine Internet-Verbindung! Sie können sich nur abmelden, wenn Sie online sind.")

      } else {
        
        if (Vue.cookie.get('__ac')) {

          console.debug('AC: ' + Vue.cookie.get('__ac'))
          
          //Vue.cookie.delete('__ac') // funktioniert das immer???
          this.deleteAcCookie()   
          console.debug('AC deleted: ' + Vue.cookie.get('__ac'))
                  
          //alert(this.userFullname + ', Sie sind nun abgemeldet! ')

          // --- anforderungen aus der Datenbank löschen ---
          zozDB.collection('requirements').delete()
            .then( resp => {
              console.debug('Requirements collection deleted! ' + resp)
            })
            .catch(error => {
              console.error(error)
            })

          // --- benutzer in den localbase löschen
          // zozDB.collection('user').doc(this.username)
          zozDB.collection('user').delete()
            .then( (resp) => {
              // --- benutzer löschen 
              console.debug('User collection deleted! ' + resp)

              this.username = ''
              this.userEmail = ''
              this.userFullname = ''

              this.message = "Sie sind nun abgemeldet."
              this.showMessage = true

              this.loggedIn = false

              setTimeout(function() {
                // router.push({'name': 'Home'}) // DEBUG
                window.location.href = "/"

              }, 1000);
              
            })
            .catch(error => {
              console.error(error)
            })
        } else {
          alert('Bereits abgemeldet, ' + this.userFullname)
        }
      }
    },
    
    login: function () {
      if (router.history.current.name !== 'Login') {
        this.loggedIn = false
        router.push({'name': 'Login'})
      }
    },
    
    isLoginForm: function () {
      if (router.history.current.name === 'Login') {
        return true
      } else {
        return false
      }
    },

    /* --- online/offline check ----- */
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online';
    },

    deleteAcCookie() {   
      document.cookie = '__ac=; Max-Age=-99999999;';
    }

  },

  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  }  


}
</script>

<style>
  #app {
    background-color: #f3f3f3;
  }
  pre {
    display: block;
    font-size: 11px !important;
    color:greenyellow;
    background-color: black;
    padding: 5px 5px;
  }
  #portal-message {
    position: fixed;
    left: 50%;
    min-width: 30%;
    bottom: 250px;
    transform: translate(-50%, -50%);
    margin: 0 auto; 
  }
  .fullname {
    text-align: right;
    font-size: 13px;
    padding: 4px 10px;
  }
  .warn {
    animation: blink 1s infinite;
  }

  @keyframes blink {
    0% { color: white}
    50% { color: #FF3D00}
  }

</style>
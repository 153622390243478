<template>
  <div class="home">
    <v-container>
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12" xl="12">
            <img alt="ZOZ" src="../assets/zoz2-logo.png" width="400">
            <div class="subheader" >
            </div>
          </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12" xl="12">

          <h3>Zertifizierung als Prüfzentrum oder Studienzentrale</h3>

          <p>
            Klinische Studien, insbesondere Therapieoptimierungsstudien, spielen in der Hämatologie und Medizinischen Onkologie eine große Rolle. Die DGHO zertifiziert Studienzentren mit einem Qualitätsmanagement-System (QM-System), welche den gesetzlichen und sonstigen Vorschriften zur ordnungsgemäßen Durchführung klinischer Studien entspricht.
          </p><p>
            Das Verfahren wurde Anfang 2020 aktualisiert und bietet die Möglichkeit, zwischen der Zertifizierung von Prüfzentren (Studienzentren, die kommerzielle klinische Studien, klinische Studien von Arbeitsgruppen und weitere Auftragsstudien durchführen) und der Zertifizierung von Studienzentralen (Studienzentren, die eigene klinische Studien konzipieren und durchführen) zu wählen.
          </p><p>
            Die Überprüfung eines QM-Systems, welches die GCP-konforme Studiendurchführung (good clinical practise) abbildet und die Novellen des Arzneimittelgesetzes (AMG), die GCP-Verordnung sowie Inspektionserfahrungen berücksichtigt, ist mit Hilfe der von der DGHO entwickelten Kriterienkataloge in Form einer Zertifizierung möglich.
          </p><p>
            Antragsteller (Studienzentralen, Studienkliniken oder -praxen) werden auf Wunsch durch an die lokalen Verhältnisse noch anzupassende Standardanweisungen (SOPs) unterstützt.
          </p><p>
            Die Fachexpertinnen und Fachexperten aus Kliniken oder Praxen, die neben Vertreterinnen und Vertretern des Zertifizierungsunternehmens die Inspektion vornehmen, sind überwiegend Mitglieder der DGHO. Eine erfolgreiche Zertifizierung kann für Audits und Inspektionen durch Behörden eine wertvolle Grundlage darstellen.
          </p>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import { router } from '../router'


export default {
  name: 'Hilfe',

  components: {
  },

  data () {
    return {
      items: [],
      loggedIn: false
    }
  },


  created: function () {
    // --- sorgt dafür, dass die Seite nur für angemeldete benutzer sichtbar ist.
    if (Vue.cookie.get('__ac')) {
      this.loggedIn = true
    } else {
      this.loggedIn = false
      if (router.history.current.name !== 'Login') {
        router.push({'name': 'Login'})
      }
    }
  },


}
</script>

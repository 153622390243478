<!-- 
===============================================================
Zeigt den Status des Projektes als Flag oben rechts an.
===============================================================
-->
<template>
  <div class="center-state-label">
    <v-chip
      :class="'mt-5 text-uppercase font-weight-bold center-label-' + 
        reviewState"
      label
    > 
    {{centerReviewStates[this.reviewState]}}
    </v-chip>
  </div>
</template>


// =========================================================


<script>

import { 
  centerReviewStates,
} from '@/config'


export default {

  /* --- DATA -------------------- */
  data () {
    return {
      centerReviewStates: centerReviewStates,
    }    
  },


  /* --- PROPS -------------------- */
  props: [
    'reviewState'
  ],
    

  /* --- CREATED -------------------- */
  created() {},


  /* --- METHODS -------------------- */
  methods: {
  },

  /* --- computed -------------------- */
  computed: {},


  /* --- mounted -------------------- */
  mounted() {
  },
  
}
</script>

<style>
.center-state-label {
  float: right;
}

.center-label-applying {
  background-color: rgb(197, 219, 0) !important;
  color: #666;
}
.center-label-evaluation {
  background-color: rgb(255, 217, 0) !important;
  color: #666;
}
.center-label-approved {
  background-color: rgb(201, 223, 0) !important;
  color: #666;
}
.center-label-certified {
  background-color: rgb(89, 223, 0) !important;
  color: #666;
}
.center-label-rejected {
  background-color: rgb(255, 30, 0) !important;
  color: white;
}
.center-label-amendment {
  background-color: rgb(255, 153, 0) !important;
  color: white;
}
.center-label-reevaluation {
  background-color: rgb(255, 251, 0) !important;
  color: #666 !important;
}
   
</style>
<template>

    <div>

      <v-form>
        <v-textarea            
          v-model="req.statement_additional"
          class="white mb-3"
          counter="1000"
          label="Ergänzende Stellungnahme"
          outlined
        ></v-textarea>

        <v-btn 
          name="submit"
          :data-uid="req.UID"
          :disabled='false'
          dark color="blue-grey"
          v-if="checkIfNotEmpty"
          @click="saveStatementData"
        >
          <span :data-uid="req.UID">Speichern</span>
        </v-btn>

        <v-btn dark 
          color="grey lighten-1" 
          class="ml-2"
          :disabled='false'
          @click="deleteStatementData"
        >
          <span :data-uid="req.UID">Löschen</span>
        </v-btn>
      </v-form>

      <!-- mini message box -->
      <div class="text-center top">
        <v-snackbar light 
          color="green lighten-5"
          v-model="snackbar"
          :timeout="timeout"
        >
          {{ clickMessage }}
        </v-snackbar>
      </div>
      <!-- /message box -->

  </div>

</template>



<script>
// import Vue from 'vue'
// import { PloneRestApi, cacheCenters } from '@/config'
// import { router } from '../router'

// const zozZentren = '/@search?portal_type=Zentrum&fullobjects=1'
import Localbase from 'localbase'
const zozDB = new Localbase('zozDB')
zozDB.config.debug = false

// console.log(cacheRequirements)
const CLICKMESSAGES = {
  'saved': 'Ihre Bewertung wurde gespeichert.',
  'saved_statement': 'Ihre Stellungnahme wurde gespeichert.',
  'deleted': 'Sie haben Ihre Bewertung gelöscht.',
  'deleted_statement': 'Stellungname entfernt.'
}


export default {
  data () {
    return {
      loggedIn: false,
      clickMessage: '',
      timeout: 2000,
      snackbar: false
    }    
  },

  props: [
    'req',
    'requirements',
    'user',
    'roles',
    'center',
    'permissions',
    'ci'
  ],
  

  created() {},

  
  mounted() {
    console.log('===== State: this.req =================')
    console.log(this.req)
  },


  methods: {

    checkIfNotEmpty: function() {
      // DUMMY TODO
    },


    /* --- how many statments are finished? -- */
    countStatements: function() {
      let counter = 0;
      for (let i=0; i<this.requirements.length; i++) {
        if (this.requirements[i].statement) {
          counter++
        }
      }
      return counter
    },


    countDivergences: function() {
      /* Anzahl der Abweichungen zählen */
      let countDiff = 0
      for (const req of this.requirements) {
        if ( req.notice_1 == 'Abweichung' || req.notice_2 == 'Abweichung') {
          countDiff = countDiff + 1
        }
      }
      return countDiff
    },

    countAdditionalStatements: function() {
      let countAddStatements = 0
      for (const req of this.requirements) {
        console.log('Add Statem.: ' + req.statement_additional)
        if (req.statement_additional) {
          // mind. 3 Zeichen
          countAddStatements = countAddStatements +1
        } 
      }
      return countAddStatements
    },


    /* --- store data to local store "Localbase" ------- */
    saveStatementData: function(event) {
      console.log('--- SPEICHERN -------------------------------')

      const reqUid = event.target.dataset.uid

      for (let i=0; i<this.requirements.length; i++) {
        if (this.requirements[i].UID == reqUid) {
          let req = this.requirements[i]

          /* --- UPDATE: Datensatz aktualisieren ---------- */
          zozDB.collection('requirements').doc(req.UID).update( {

            'statement': req.statement,
            'statement_additional': req.statement_additional,
            'statement_author': this.user['fullname'],

            'timestamp': new Date(),
            'username':  this.user['username'],

          }).then(response =>{
            console.log(response)
            this.clickMessage = CLICKMESSAGES['saved_statement']
            this.snackbar = true

            // is the application ready to submit?
            const cas = this.countAdditionalStatements()
            const cd = this.countDivergences()            
            if (cas >= cd) {
              this.$emit('applicationReadyEvent', cd)
              console.log(' EMIT applicationReadyEvent: ' + true)
            }
            // --- Synchronisierung anstoßen ---
            this.$emit('synchonizeServerEvent')

          }).catch(e => {
            console.log(e)
            alert(e)
          })
          
          this.$set(this.requirements, i, req)
        }
      }
    },


    /* --- DELETE: delete statement from "Localbase" ---- */
    deleteStatementData: function(event) {
      const reqUid = event.target.dataset.uid

      for (let i=0; i<this.requirements.length; i++) {
        if (this.requirements[i].UID == reqUid) {
          let req = this.requirements[i]

          /* ---- Statement aus der Localbase löschen ---- */
          zozDB.collection('requirements').doc(req.UID).update({
            statement_additional: '',
            timestamp: new Date(),
            username: this.user['username']
          })
          .then(response => {
              console.log(response)
              this.clickMessage = CLICKMESSAGES['deleted_statement']
              this.snackbar = true

              req.statement_additional = null

              /* aktualisiere Inhalt der Formular Felder */
              this.$set(this.requirements, i, req)
              this.$emit('applicationNotReadyEvent', 0)
              
              // --- Synchronisierung anstoßen ---
              this.$emit('synchonizeServerEvent')

          })
          .catch(error => {
              console.log(error)
              alert(error)
          })

        }
      }

    },


    niceDate: function(dateString) {
      const nd = new Date( Date.parse(dateString) );
      const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
      return nd.toLocaleDateString('de-DE', options)
    }
  },
  
  computed: {},
  
  
}
</script>




<style>
</style>
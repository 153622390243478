<template>
  <div class="Zentren">
  <pre v-if="debugMode">
    USER:   {{ user }}
  </pre>
    <v-container >
      <!-- angemeldet -->
      <v-expansion-panels class="my-4" tile accordion >
        <v-expansion-panel 
            class="py-4 px-2 my-1"
            v-for="item in items"
            :key="item.id"
        >
          <v-expansion-panel-header 
            :data-UID="item.UID"
            color="white"
          >
            <h3>{{ item.title }}</h3>

            <!-- zentrum status -->
            <v-chip
              :class="'text-uppercase font-weight-bold center-list center-label-' + 
                item.review_state"
              label
            > 
              {{centerReviewStates[item.review_state]}}
            </v-chip>

          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-card flat>

              <v-card-subtitle class="px-0 py-2 mx-0">
                <div>{{ item.description.data }}</div>
              </v-card-subtitle>

              <v-card-text class="pa-0 ma-0">
                <div class="az my-2">
                  <h4>Aktenzeichen: {{ item.aktenzeichen}}</h4>
                </div>
                <div class="modified my-2">
                  <span>Stand: {{ niceDate(item.modified) }}</span>
                </div>
                <div class="description my-2">
                  <h4>Beschreibung:</h4> 
                  <span 
                    v-if="item.beschreibung_des_zentrums"
                    v-html="item.beschreibung_des_zentrums.data" />
                </div>
                <address class="address my-2">
                  <h4>Adresse:</h4>
                  {{ item.adresse}} <br/>
                  {{ item.plz}} - {{ item.ort}}
                </address>
                <div class="contact my-2">
                  <h4>Kontaktpersonen: </h4>
                  <span 
                    v-if="item.kontaktpersonen" 
                    v-html="item.kontaktpersonen.replace('\n','<br />')" />
                </div>
                <div class="inspektors my-2">
                  <h4>Zuständige Inspektoren: </h4>
                  <span 
                    v-if="item.inspektoren" 
                    v-html="item.inspektoren.replace('\n','<br />')" />
                </div>
              </v-card-text>
              <v-card-actions class="mb-3 pb-8">
                  <v-btn 
                    fab dark small 
                    color="indigo" 
                    right absolute 
                    class="my-4"
                    @click="$router.push( {
                      name: 'Zentrum', 
                      params: {
                        'uid': item.UID,
                        'item': item,
                        'user': user
                      }
                    })"
                  >
                    <v-icon dark fab>
                      mdi-arrow-right-bold                  
                    </v-icon>
                  </v-btn>
              </v-card-actions>
            </v-card>            
          </v-expansion-panel-content>

        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

  </div>
</template>




<script>

import Vue from 'vue'
import { router } from '../router'
import { 
  PloneRestApi, 
  cacheCenters,  
  centerReviewStates,
  zozDB,
  debugMode
} from '@/config'

const zozZentren = '/@search?portal_type=Zentrum&fullobjects=0'

export default {
  data () {
    return {
      user: Object,
      debugMode: JSON.parse(debugMode), // true/false-String,
      items: [],
      loggedIn: false,
      centerReviewStates: centerReviewStates
    }
  },

  /* ---------- before  ----------------- */
  beforeCreate() {
    // -- get user form localbase ---
    zozDB.collection('user').get()
    .then(user => {
      if(user) {
        console.debug(user)
        this.user = {
          username: user[0].username,
          fullname: user[0].fullname,
          email: user[0].userEmail
        }
      }
    })
  },

  created: function () {
    // --- sorgt dafür, dass die Seite nur für angemeldete benutzer sichtbar ist.
    if (Vue.cookie.get('__ac')) {
      this.loggedIn = true
    } else {
      this.loggedIn = false
      if (router.history.current.name !== 'Login') {
        router.push({'name': 'Login'})
      }
    }
  },


  methods: {
    niceDate: function(dateString) {
      const nd = new Date( Date.parse(dateString) );
      const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
      return nd.toLocaleDateString('de-DE', options)
    }
  },
  
  computed: {},
  
  mounted() {

    /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
    /*          Get the Center data                               */
    /* -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+ */
    PloneRestApi.defaults.headers['Authorization'] = 'Bearer ' + this.$cookie.get('__ac')
    PloneRestApi(zozZentren, {cache: cacheCenters})
    .then(response => {
      this.items = response.data.items
    })
  },
  
}
</script>

<style>
p,li {
  margin: 5px auto;
  line-height: 1.5em
}
.center-list {
  width: 180px; 
  max-width: 180px; 
  margin-right: 40px;
  font-size: 12px !important;
}
</style>
<!-- 
===============================================================
Die Filter-Reiter werden bei den Zentrum oberhalb der Anforder-
ungen angezeigt. Filter sind z.B. 'Apotheke', 'Radiologie' etc.
===============================================================
-->
<template>
  <v-card>
    <v-tabs
      :background-color="bgColor"
      center-active
      left
      dark>
      <v-tab
        v-for="tab in filterTabs"
        :key="tab"
        :data-tab="tab"
        @click="filterRequirements"
      >{{ tab }}</v-tab>
    </v-tabs>
  </v-card>
</template>




<script>

import { 
  filterStructureTabs,
  filterSubjectTabs,
  filterStateTabs,
  evaluationVisibleForCenterStates
} from '@/config'

console.log(evaluationVisibleForCenterStates)

export default {

  /* --- DATA -------------------- */
  data () {
    return {
      filterStructureTabs: filterStructureTabs,
      filterSubjectTabs: filterSubjectTabs,
      filterStateTabs: filterStateTabs,
      evaluationVisibleForCenterStates: evaluationVisibleForCenterStates,
      filterTabs: []  
    }    
  },


  /* --- PROPS -------------------- */
  props: [
    'requirements',
    'center',
    'ci',
    'bgColor',
    'filterMethod'
  ],
    

  /* --- CREATED -------------------- */
  mounted() {

    // --- STRUCTUE TABS -------------------------------------------------
    if (this.filterMethod == 'filterRequirementsOnStructure') {
      this.filterTabs = this.filterStructureTabs
    }
    // --- SUBECT TABS ------------------------------------------------- 
    if (this.filterMethod == 'filterRequirementsOnSubject') {
      this.filterTabs = this.filterSubjectTabs
    }
    // --- STATEMENT TABS -------------------------------------------------
    if (this.filterMethod == 'filterRequirementsOnState') {
      // center sehen eingeschränkte Tabs
      if ( this.ci == 0 ) {
        this.filterTabs = this.filterStateTabs 
        if ( this.evaluationVisibleForCenterStates.includes(this.center.review_state) ) {
          // --- dann entferne 'Erfüllt', 'nicht erfüllt, 'Abweichungen'
  
          let index = 0
          index = this.filterTabs.indexOf('Erfüllt')
          if (index > -1) { this.filterTabs.splice(index, 1)}

          index = this.filterTabs.indexOf('Nicht erfüllt')
          if (index > -1) { this.filterTabs.splice(index, 1)}

          index = this.filterTabs.indexOf('Abweichungen')
          if (index > -1) { this.filterTabs.splice(index, 1)}
          
        }
      } else {
        this.filterTabs = this.filterStateTabs
      }
    }
  },


  /* --- METHODS -------------------- */
  methods: {

    /* --- Fildermethode wird ausgewählt --- */
    filterRequirements: function(e) {

      // alle aufgeklappten Anforderungen schließen ---
      const tabContents = document.getElementsByClassName('v-expansion-panel-content')
      tabContents.forEach(element => {
        element.style.display = 'none'
      });
      
      const clickTab = e.target.dataset.tab

      const structureFilterTabs = document.getElementById('structureFilter').getElementsByClassName('v-tab')
      const subjectFilterTabs = document.getElementById('subjectFilter').getElementsByClassName('v-tab')
      const statusFilterTabs = document.getElementById('statusFilter').getElementsByClassName('v-tab')

      const structureFilterActiveTab = document.getElementById('structureFilter').getElementsByClassName('v-tab--active')
      const subjectFilterActiveTab = document.getElementById('subjectFilter').getElementsByClassName('v-tab--active')
      const statusFilterActiveTab = document.getElementById('statusFilter').getElementsByClassName('v-tab--active')

      const activeFilterTabs = { 
        structureTab: structureFilterActiveTab[0].getAttribute('data-tab'), 
        subjectTab: subjectFilterActiveTab[0].getAttribute('data-tab'), 
        statusTab: statusFilterActiveTab[0].getAttribute('data-tab')
      } 

      for ( let i=0; i<structureFilterTabs.length; i++ ) {
        if ( structureFilterTabs[i].getAttribute('data-tab') == clickTab ) {
          activeFilterTabs['structureTab'] = clickTab
        }
      }
      for ( let i=0; i<subjectFilterTabs.length; i++ ) {
        if ( subjectFilterTabs[i].getAttribute('data-tab') == clickTab ) {
          activeFilterTabs['subjectTab'] = clickTab
        }
      }
      for ( let i=0; i<statusFilterTabs.length; i++ ) {
        if ( statusFilterTabs[i].getAttribute('data-tab') == clickTab ) {
          activeFilterTabs['statusTab'] = clickTab
        }
      }

      console.log('-------  FILTERTABS --------------')
      console.log('STRU-TAB:  ' + activeFilterTabs['structureTab'])
      console.log('SUBJ-TAB:  ' + activeFilterTabs['subjectTab'])
      console.log('STAUS_TAB: ' + activeFilterTabs['statusTab'])

      // -- Alle Anforderungen ert mal auf unsichtbar stellen, 
      //    anschließend je nach Filter einblenden 
      //
      for ( let i=0; i<this.requirements.length; i++ ) {
        const req = this.requirements[i]
        req.visible = false
        this.$set(this.requirements, i, req) 
      }
      // --- filter 1: filter by Structure && Area ----
      this.filterRequirementsByTabs( 
        activeFilterTabs['structureTab'],
        activeFilterTabs['subjectTab'],
      )
      // --- filter 2: Requirements status
      this.filterRequirementsByStatus( activeFilterTabs['statusTab'] )
    },


    /* --- tab-filter nach Audit Structure = Plone Subject ---- */
    filterRequirementsByTabs: function(tab1, tab2) {
      for (let i=0; i<this.requirements.length; i++) {
        const req = this.requirements[i]

        if ( req.subjects.includes(tab1) && req.subjects.includes(tab2) ) {
          req.visible = true  
        } else if ( tab1 == 'Start' && req.subjects.includes(tab2) ) {
          req.visible = true  
        } else if ( req.subjects.includes(tab1) && tab2 == 'Übersicht' ) {
          req.visible = true  
        } else if ( tab1 == 'Start' && tab2 == 'Übersicht' ) {
          req.visible = true  
        } else {
          req.visible = false
        }
        this.$set(this.requirements, i, req) 
      }
    },


    /* --- tab-filter nach Projektstatus ---- */
    filterRequirementsByStatus: function(cTab) {
      
      for ( let i=0; i<this.requirements.length; i++ ) {
        const req = this.requirements[i]
        
        if (req.visible) {

          // --- alle, bearbeitert + unbearbeitet 
          if (cTab == 'Alle') {
            // #### DEBUG ###### req.visible = true
          } 

          else if (cTab == 'Bearbeitet') {
            if ( this.ci != 0) { // inspektoren
              if (req['rating_1'] == null && req['rating_2'] == null) {
                req.visible = false  
              }
            } else { // center
              if ( req['statement'] == null ) {
                req.visible = false
              }
            }
          } 
          else if (cTab == 'Unbearbeitet') { 
            if ( this.ci != 0) { // inspektoren
              if (req['rating_1'] != null || req['rating_2'] != null) {
                req.visible = false  
              }
            } else { // center
              if ( req['statement'] != null ) {
                req.visible = false
              }
            }
          }

          else if (cTab == 'Erfüllt') {
            // --- erfüllt | nicht erfüllt
            if ( this.ci != 0) { // inspektoren
              if ( req['rating_' + this.ci] != 'Erfüllt' ) {
                req.visible = false
              }
            } else { // center
              if ( req['rating_1'] != 'Erfüllt' && req['rating_2'] != 'Erfüllt') {
                req.visible = false
              }
            }
          } 
          else if (cTab == 'Nicht erfüllt') {
            if ( this.ci != 0) { // inspektoren
              if ( req['rating_' + this.ci] != 'Nicht erfüllt' ) {
                req.visible = false
              }
            } else { // center
              if ( req['rating_1'] != 'Erfüllt' || req['rating_2'] != 'Erfüllt') {
                req.visible = false
              }
            }
          } 
          else if (cTab == 'Abweichungen') {
            console.log('NOTIZen: ' + req['notice_1'] + ' | ' + req['notice_2'])
            if ( req['notice_1'] != 'Abweichung' && req['notice_2']  != 'Abweichung' ) {
              req.visible = false
            }
          } 
          else if (cTab == 'Ergänzungen') {
            console.log("ERGÄNZUNGEN", req['statement_additional'])
            if ( !req['statement_additional'] ) {
              req.visible = false
            }
          } 

          else {
            req.visible = false
          }
          
          console.log('     ' + req.visible + ' | Title: ' + req.title)
          this.$set(this.requirements, i, req) 
        }
      }
    },

  },

  /* --- computed -------------------- */
  computed: {},


  
}
</script>

<style>
   
</style>
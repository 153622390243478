import Vue from 'vue'
import VueRouter from 'vue-router'
// *** import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Center from '../views/Center.vue'
import CenterListing from '../views/CenterListing.vue'
import Help from '../views/Help.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: {name: 'Login'}
    // *** component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    redirect: {name: 'Login'}
  },
  {
    path: '/center/:uid',
    name: 'Zentrum',
    component: Center
  },
  {
    path: '/center-listing',
    name: 'Zentren',
    component: CenterListing
  },
  {
    path: '/help',
    name: 'Hilfe',
    component: Help
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue')
  },
  {
    path: '*',
    redirect: '/'
  }  
]

// left navigation -----------
const navigationItems = [
  // *** { text:'Startseite',  icon: 'mdi-home',               'route': '/' },
  { text:'Zentren',     icon: 'mdi-hospital-building',  'route': '/center-listing'  },
  { text:'Infos',       icon: 'mdi-help',               'route': '/help' }      
]

const router = new VueRouter({
  routes
})

export {router, navigationItems}

<template>

  <div>
    <!-- v-if="
    this.permissions.includes('Modify portal content') && !ci == 0" -->
    <v-form>
      <v-text-field
        id="uid"
        name="uid"
        v-model="req.UID"
        v-show="false"
      ></v-text-field>

<pre v-if="debugMode">
  DEBUG_MODE: {{ debugMode }}
  RATING:     1: {{ req['rating_1'] }} | 2:{{ req['rating_2'] }}
  TEXTITEMS:  {{ req.textitems }}
  FLAG_COLORS:{{ req.flag_colors }}
  USER:       {{user['fullname']}}
  
</pre>

      <div v-if="prevEval" class="prev-eval">
        <div>
          Sie sehen hier die bisherige Bewertung von: 
          <h3>{{ req['author_evaluation_' + ci] }}</h3>
        </div>
        <div>
          Sie können die Bewertung neu bearbeiten und speichern,<br /> 
          oder Sie können die Bewertung einfach bestätigen, 
          indem Sie auf <b>Bestätigen</b> klicken.
        </div>

      </div>

      <v-select
        v-model="req['rating_' + ci]"
        :items="req.textitems"
        @change="ratingFieldChanged"
        label="Bewertung"
        class="bewertung"
        outlined 
        filled
      ></v-select> 

      <v-textarea
        v-model="req['evaluation_tb_' + ci]"
        readonly
        auto-grow
        dense
        disabled
        v-if="evaluationTbVisible"
      ></v-textarea>

      <v-select
        v-model="req['notice_' + ci]"
        v-if="notesVisible"
        :items="getNotes()"
        @change="noticeFieldChanged"
        label="F/H/A/B"
        class="anmerkung"
        placeholder="--- bite auswählen ---"
        outlined 
        filled
      ></v-select> 

     <v-textarea
        class="evaluation-field"
        v-model="req['evaluation_' + ci]"
        counter="5000"
        label="Bewertung Fachexperte"
        outlined
        v-if="evaluationVisible"
        @keydown="evaluationFieldChanged"
      ></v-textarea>

      <v-btn 
        name="submit"
        :data-uid="req.UID"
        :disabled='false'
        :loading='this.snackbar'
        :class="'btn-confirm-' + prevEval"
        dark 
        v-if="checkIfNotEmpty"
        @click="saveEvaluationData"
      >
        <span 
          v-if="prevEval" 
          :data-uid="req.UID">Bestätigen</span>
        <span 
          v-else 
          :data-uid="req.UID">Speichern</span>

      </v-btn>

      <v-btn dark 
        color="grey lighten-1" 
        class="ml-2"
        :disabled='false'
        v-if="!prevEval"
        @click="resetEvaluationData"
      >
        <span :data-uid="req.UID">Zurücksetzen</span>
      </v-btn>

    </v-form>

    <!-- mini message box -->
    <div class="text-center top">
      <v-snackbar 
        color="green"
        v-model="snackbar"
        class="action-message"
        :timeout="timeout"
      >
        {{ clickMessage }}
      </v-snackbar>
    </div>
    <!-- /message box -->

  </div>
  
</template>
















<script>
// import Vue from 'vue'
import { debugMode } from '@/config'
// import { router } from '../router'

// const zozZentren = '/@search?portal_type=Zentrum&fullobjects=1'

import Localbase from 'localbase'
const zozDB = new Localbase('zozDB')
zozDB.config.debug = false

// console.log(cacheRequirements)
const CLICKMESSAGES = {
  'saved': 'Ihre Bewertung wurde gespeichert.',
  'reset': 'Ihre Änderungen wurden zurückgesetzt.',
  'deleted': 'Sie haben Ihre Bewertung gelöscht.'
}
const NOTES = {
  // not approved
  'F': 'Feststellung',
  'H': 'Hinweis',
  'A': 'Abweichung',
  // approved
  'N': 'Anmerkung',
  // not applicable
  'B': 'Begründung'
}
const APPROVED = 'Erfüllt'
const NOT_APPROVED = 'Nicht erfüllt'




export default {

  /* --- DATA -------------------- */
  data () {
    return {
      prevEval: false,
      loggedIn: false,
      clickMessage: '',
      timeout: 2000,
      snackbar: false,
      // --- visibility flages ---
      notesVisible: false,
      evaluationVisible: false,
      evaluationTbVisible: false,
      isDisabledReset: true, // TODO: notwendig?
      isDisabledSubmit: true, // TODO: notwendig?
      countDivergences: 0,
      // 
      notes: NOTES,
      //
      debugMode: JSON.parse(debugMode), // true/false-String,

    }    
  },

  /* --- PROPS -------------------- */
  props: [
    'req',
    'requirements',
    'user',
    'roles',
    'center',
    'permissions',
    'ci'
  ],
  

  /* --- CREATED -------------------- */
  created() {
  },


  /* --- METHODS -------------------- */
  methods: {

    initialFieldsDisplay: function() {
      const rating = this.req['rating_' + this.ci]
      const notice = this.req['notice_' + this.ci]

      if (rating) {
        this.evaluationTbVisible = true
      } else {
        this.evaluationTbVisible = false
      }

      if (notice) {
        this.notesVisible = true
        this.evaluationVisible = true
      } else {
        this.notesVisible = false
        this.evaluationVisible = false
      }
    },

    ratingFieldChanged: function() {
      this.req['notice_' + this.ci] = ''
      this.req['evaluation_' + this.ci] = ''
      this.notesVisible = false
      this.evaluationVisible = false
      this.setEvaluationTbField()
      // --- rezert
      this.resetRezertColorFlag(this.req)
    },

    noticeFieldChanged: function() {
      // --- löschen eval feld ---
      // this.req['evaluation_' + this.ci] = ''
      // --- 
      if (this.req['notice_' + this.ci]) {
        this.evaluationVisible = true
      } else {
        this.evaluationVisible = false
      }
      this.resetRezertColorFlag(this.req)
    },

    evaluationFieldChanged: function() {
      this.resetRezertColorFlag(this.req)
    },

    /* --- sicht die passende Anmerkung --- */
    getNotesXXXXXXXXXXXXXXXXXXXXXX: function() {
      if (this.req['rating_' + this.ci] == APPROVED) {
        return [' --- ', this.notes['N']]
      } else {
        return [' --- ', this.notes['F'], this.notes['H'], this.notes['A']]
      }
    },

    /* --- sucht die passende Anmerkung --- */
    getNotes: function() {
      if (this.req['rating_' + this.ci] == APPROVED) {
        return [' --- ', this.notes['N']]
      } else if (this.req['rating_' + this.ci] == NOT_APPROVED) {
        return [' --- ', this.notes['F'], this.notes['H'], this.notes['A']]
      } else {
        return [' --- ', this.notes['B']]
      }
    },

    checkIfNotEmpty: function() {
      // DUMMY TODO
    },


    /* --- how many statments are finished? -- */
    /* ---
    #LM 2024-06: Achtung: funktioniert bisher nur, wenn beide inspektoren 
                eingetragen sind und beide auch alle Stellungnahmen bearbeitet haben
    */
    countEvaluations: function() {
      let counter = 0;
      for (let i=0; i<this.requirements.length; i++) {
        //if (this.requirements[i]['rating_1'] && this.requirements[i]['rating_2']) {
        if (this.requirements[i]['rating_2']) {
          counter++
        } 
      }
      return counter
    },



    /* --- store data to local store "Localbase" ------- */
    saveEvaluationData: function(event) {
      console.log('--- SPEICHERN -------------------------------')

      const reqUid = event.target.dataset.uid

      for (let i=0; i<this.requirements.length; i++) {
        if (this.requirements[i].UID == reqUid) {
          let req = this.requirements[i]
          
          req.flag_colors[3] = ''
          this.prevEval = false

          // this.resetRezertColorFlag(req)
          req['author_evaluation_' + this.ci] = this.user['fullname']

          const ratingKey = 'rating_' + this.ci
          const evaluationKey = 'evaluation_' + this.ci
          const evaluationTbKey = 'evaluation_tb_' + this.ci
          const noticeKey = 'notice_' + this.ci
          const authorEvaluationkey = 'author_evaluation_' + this.ci

          /* --- UPDATE: Datensatz aktualisieren ---------- */
          zozDB.collection('requirements').doc(req.UID).update( {

            [ratingKey]:  req['rating_' + this.ci], //this.getRatingText(req), // Brauche den (angezeigten) Text, nicht die Value
            [evaluationTbKey]: req['evaluation_tb_' + this.ci],
            [evaluationKey]: req['evaluation_' + this.ci],
            [noticeKey]: req['notice_' + this.ci],
            [authorEvaluationkey]: this.user['fullname'],

            'timestamp': new Date(),
            'username':  this.user['username'],

          }).then(response => {
              console.log(response)
              this.clickMessage = CLICKMESSAGES['saved']
              this.snackbar = true

              // is evaluation complete?
              this.checkIfEvaluationIsFinished()

              // Abweichungen vorhanden?
              this.countDivergences = 0;
              this.countRezert = 0;

              for (let i = 0; i < this.requirements.length; i++) {
                const req = this.requirements[i]
                console.log("NOTIZEN: " + req.notice_1 + ', ' + req.notice_2)

                // -- gibt es noch Abweichungen?
                if ( req.notice_1 == 'Abweichung' || req.notice_2 == 'Abweichung') {
                  this.countDivergences++
                }
                // -- gibt es noch unbestaetige Anforderungen?
                if ( req['author_evaluation_' + this.ci] && 
                     req['author_evaluation_' + this.ci] != this.user['fullname']) {
                  this.countRezert
                }
              }
              if (this.countDivergences == 0) {
                this.$emit('noDivergencesEvent')
              } else {
                this.$emit('divergencesEvent')
              }
              if (this.countRezert == 0) {
                this.$emit('noRezertEvent')
              } else {
                this.$emit('rezertEvent')
              }

              // --- Synchronisierung anstoßen ---
              this.$emit('synchonizeServerEvent')

          }).catch( e => {
            console.log(e)
            alert(e)
          })
          /* Requirement in der Liste aktualisieren | $set */
          this.$set(this.requirements, i, req)
        }
      }
    },


    /* --- delete total evaluation from "Localbase" ---- */
    deleteEvaluationData: function(event) {
      const reqUid = event.target.dataset.uid

      for (let i=0; i<this.requirements.length; i++) {
        if (this.requirements[i].UID == reqUid) {
          let req = this.requirements[i]

          /* ---- Evaluation aus der Localbase löschen ---- */
          zozDB.collection('requirements').doc(req.UID).update({
            ['evaluation_tb' + this.ci]: '',
            ['evaluation_' + this.ci]: '',
            ['notice_' + this.ci]: '',
            ['author_evaluation_' + this.ci]: '',
            ['rating_' + this.ci]: '',
            timestamp: new Date(),
            username: this.user['username']
          })
            .then(response => {
              console.log(response)
              this.clickMessage = CLICKMESSAGES['deleted']
              this.snackbar = true
              req['evaluation_tb_' + this.ci] = null,
              req['evaluation_' + this.ci] = null,
              req['notice_' + this.ci] = null,
              req['author_evaluation_' + this.ci] = null,
              req['rating_' + this.ci] = null

              // --- set flag color -------
              this.setFlagColor()
              this.setRezertColorFlag(req)
              req['author_evaluation_' + this.ci] = this.user['fullname']

              /* aktualisiere Inhalt der Formular Felder */
              this.$set(this.requirements, i, req)
              })
            .catch(error => {
              console.log(error)
            })            

        }
      }
    },

    /* --- reset evaluation = reload from "Localbase" ---- */
    resetEvaluationData: function(event) {

      const reqUid = event.target.dataset.uid

      for (let i=0; i<this.requirements.length; i++) {
        if (this.requirements[i].UID == reqUid) {
          let req = this.requirements[i]

          /* ---- Evaluation aus der Localbase neu einlesen ---- */
          zozDB.collection('requirements').doc(req.UID).get()
            .then(dbReq => {

              this.clickMessage = CLICKMESSAGES['reset']
              this.snackbar = true

              // --- reset fields -----
              const resetFields = [
                'rating', 
                'evaluation_tb', 
                'notice', 
                'evaluation'
              ]
              for (let i=0; i<resetFields.length; i++) {
                req[ resetFields[i] + '_' + this.ci] = dbReq[resetFields[i] + '_' + this.ci]  
              }

              // --- set flag color -------
              this.setFlagColor()

              this.setRezertColorFlag(req)
              //req['author_evaluation_' + this.ci] = this.user['fullname']
              
              this.initialFieldsDisplay()

              /* aktualisiere Inhalt der Formular Felder */
              this.$set(this.requirements, i, req)
                          
            })  

            .catch(error => {
              console.log(error)
            })            

        }
      }


    },

    /* --- setzt die Farbe der Fähnchen und der linken Leiste --- */
    setFlagColor: function() {
        for (let r = 0; r < this.req.textitems.length; r++) {
          console.log('TEXTITEM R VALUE: ' + this.req.textitems[r].value)
          if ( this.req.textitems[r].value == this.req['rating_' + this.ci]) {
            this.req.flag_colors[this.ci] = this.req.textitems[r].flag_color
          }

        }
    },

    /* --- Inhalt selectbox  ----- */
    getRatingText: function(req) {
      for (let r = 0; r < req.textitems.length; r++) {
        console.log(req.textitems[r].value + ' | | ' + req['rating_' + this.ci])
        if ( req.textitems[r].value == req['rating_' + this.ci]) {
          req['rating_' + this.ci] = req.textitems[r].text
        }
      }    
    },

    resetRezertColorFlag: function(req) {
      req.flag_colors[3] = ''
      this.prevEval = false
    },    

    setRezertColorFlag: function(req) {
      console.debug("AUTOR:   " + req['author_evaluation_' + this.ci])
      console.debug("CURRENT: " + this.user['fullname'] )
      if ( req['author_evaluation_' + this.ci] && 
           req['author_evaluation_' + this.ci] != this.user['fullname'] ) { 
        req.flag_colors[3] = 'rezert'
        this.prevEval = true
      } else {
        req.flag_colors[3] = ''
        this.prevEval = false
      }
    },

    /* --- Textbausteine schreiben ----- */
    setEvaluationTbField: function() {
      console.log('RATING: ' + this.req['rating_' + this.ci] ) // hier steht die Value !

      for (let i = 0; i < this.requirements.length; i++) {
        if ( this.requirements[i] == this.req ) {
          for (let r = 0; r < this.req.textitems.length; r++) {
            if ( this.req.textitems[r].value == this.req['rating_' + this.ci]) {

              const now = new Date()
              const options = { 
                weekday: 'long', 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric', 
                hour: 'numeric', 
                minute: 'numeric' 
              }
              const separator = '--- ' + now.
                toLocaleDateString('de-DE', options) + '--- '+ this.req.textitems[r].value +' ---- \n'

              this.req['evaluation_tb_' + this.ci] = separator + this.req.textitems[r].description
              
              // --- eval-feld anzeichen
              this.evaluationTbVisible = true
              this.notesVisible = true

              this.req.flag_colors[this.ci] = this.req.textitems[r].flag_color

              this.$set(this.requirements, i, this.req)
            }
          }
        }
      }
    },


    niceDate: function(dateString) {
      const nd = new Date( Date.parse(dateString) );
      const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
      return nd.toLocaleDateString('de-DE', options)
    },


    checkIfEvaluationIsFinished: function() {
      console.log("======================== checkIfEvaluationIsFinished =================================================")
      console.log(this.countEvaluations())
      console.log(this.requirements.length)
      console.log(this.countEvaluations() == this.requirements.length)
      
      if ( this.countEvaluations() == this.requirements.length) { 
        if (this.ci == 2) { // nur ChiefInspctors dürfen abschließen.
          this.$emit('evaluationReadyEvent')
        }
      }
    }


  },

  /* --- computed -------------------- */
  computed: {},


  /* --- mounted -------------------- */
  mounted() {
    console.debug('===== Eval: this.req =================')
    console.debug(this.req)

    this.checkIfEvaluationIsFinished()
    this.initialFieldsDisplay()

    this.setRezertColorFlag(this.req)
    /*
    if (this.req['author_evaluation_' + this.ci] != this.user['fullname'] ) {
      this.prevEval = true
      alert("sdsd")
    }
    */
    

  },
  

}
</script>









<style scope lang="scss">
.anmerkung {
  color: blueviolet;
  max-width: 260px !important;
}

.bewertung {
  max-width: 260px !important;
}

/* individual styles */
.theme--light.v-input--is-disabled input, 
.theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 0.7);
    border: none !important;
}

.prev-eval {
  margin: 0 0 20px 0;
  padding: 20px;
  background-color: lighten(blue, 20%);
  color: white;
  font-size: 14px;
  font-weight: bold;
  .btn-confirm {
    background-color: darken(blue, 20%);
    border:1px solid rgb(33, 33, 115);
    padding: 5px 10px;
    font-size: 10px;
  }
}

.btn-confirm-true {
  background-color: lighten(blue, 20%) !important;
}
.btn-confirm-false {
  background-color: rgb(74, 132, 112) !important;
}
</style>
import axios from 'axios'
import Localbase from 'localbase'
import { 
  cacheAdapterEnhancer, 
  Cache, 
  throttleAdapterEnhancer 
} from 'axios-extensions';


// === Daten werden aus der Datei env.local bzw. env.production geladen ===

// --- localbase ---
// const localbaseDB = process.env.VUE_APP_LOCALBASE_DB
const localbaseName = process.env.VUE_APP_LOCALBASE_NAME
const localbaseDebug = process.env.VUE_APP_LOCALBASE_DEBUG

const zozDB = new Localbase(localbaseName)
// --- /localbase ---

const serverMode = process.env.VUE_APP_SERVER_MODE
const debugMode = process.env.VUE_APP_DEBUG
const zozBaseUrl = process.env.VUE_APP_ZOZ_BASEURL
const zozLoginUrl = process.env.VUE_APP_ZOZ_LOGIN_URL
const zozUsersUrl = process.env.VUE_APP_ZOZ_USERS_URL

// --- wird nicht verwendet; einstellung im apache -----
const ploneInstancePath = process.env.VUE_APP_PLONE_INSTANCE_PATH

const projectStatus = process.env.VUE_APP_PROJECT_STATUS
const softwareVersion = process.env.VUE_APP_SOFTWARE_VERSION


/* --- configure Plone REST API ---------------- */
const PloneRestApi = axios.create({
  baseURL: zozBaseUrl,
  timeout: 6000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Cache-Control': 'no-cache'    
  },
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, true))
})

const cacheRequirements = new Cache()
const cacheCenters = new Cache()
const cacheWebContent = new Cache()


/* --- zoz2-Service-Adresse == Frau Teuteberg ? ---- */
const zoz2EmailAddress = "service@onkologie-zertifizierung.de"

/* ----- Übersetzung für Workflow Stati ---- */
const centerReviewStates = {
  'applying': 'in der Beantragung',
  'approved': 'wurde positiv bewertet',
  'archived': 'ist archiviert',
  'certified': 'ist zertifiziert',
  'draft': 'ist in Vorbereitung',
  'evaluation': 'wird auditiert',
  'reevaluation': 'wird re-evaluiert',
  'rejected': 'wurde zurückgewiesemn',
  'retracted': 'wurde zurückgezogen',
  'submitted': 'wurde eingereicht',
  'amendment': 'wird ergänzt'
}

const evaluationVisibleForCenterStates = [
  // die WF-Statis, in denen die Center die Evaluierung anschauen können
  'approved',
  'archived',
  'certified',
  'reevaluation',
  'evaluation',
  'rejected',
  'retracted',
  'amendment'
]

/* -------- mögliche Bewertungen ----- 
  #LM: Wird nicht verwendet
*/
const shortEvaluations = [
  'Erfüllt', 
  'Nicht erfüllt', 
  'Nicht erforderlich', 
  'Nachbesserung erforderlich'
]

/* ------- Filter für die Anforderungen nach Abteilungen --- */
const filterStructureTabs = [
  'Start',
  'Audit',
  'Vorstellung',
  'Station',
  'Ambulanz',
  'Apotheke',
  'Studienzentrum',
  'Aktenstudium'
]

const filterSubjectTabs = [
  'Übersicht',
  'Ärzte',
  'Kooperationen',
  'Prozesse',
  'Studien',
  'Pflege',
  'Zahlen',
  'Labor',
  'Räumlichkeiten',
]

const filterStateTabs = [
  'Alle',
  'Bearbeitet',
  'Unbearbeitet',
  'Erfüllt',
  'Nicht erfüllt',
  'Abweichungen',
  'Ergänzungen'
]

const alertMessages = {
  'APPLICATION_SUBMIT': 'Ihr Antrag wird jetzt eingereicht!',
  'CERTIFICATION_APPROVED': 'Eine Zertifizerung wird empfohlen.',
  'CERTIFICATION_DENYIED': 'Eine Zertifizerung nicht empfohlen.',
  'REQUEST_ADDITIONAL_STATMENTS': 'Es werden ergänzende Stellungnahmen angefordert.',
}

/* ... download documente --- */
const mimetypes = {
  'application/pdf': 'mdi-file-pdf-box',
  'image/png': 'mdi-file-image',
  'image/jpg': 'mdi-file-image',
  'image/jpeg': 'mdi-file-image',
  'image/gif': 'mdi-file-image',
  'application/msword': 'mdi-file-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'mdi-file-word',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'mdi-file-excel',
  'application/vnd.oasis.opendocument.spreadsheet': 'mdi-file-excel',
  'application/zip': 'mdi-folder-zip'
}
const icon_colors = {
  'application/pdf': 'red',
  'image/png': 'blue',
  'image/jpeg': 'yellow',
  'image/jpg': 'lightblue',
  'application/msword': 'orange',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'orange',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'blue',
  'application/vnd.oasis.opendocument.spreadsheet': 'blue',
  'application/zip': 'brown'

}


// ---- export all ---------
export { 
  softwareVersion,
  projectStatus,

  localbaseDebug,
  localbaseName,
  zozDB,

  PloneRestApi, 
  ploneInstancePath, 

  serverMode,
  debugMode,

  zozBaseUrl,
  zozLoginUrl,
  zozUsersUrl,
  centerReviewStates,
  evaluationVisibleForCenterStates,

  shortEvaluations,
  cacheRequirements,
  cacheCenters,
  cacheWebContent,
  filterStructureTabs,
  filterSubjectTabs,
  filterStateTabs,
  alertMessages,
  mimetypes,
  icon_colors,

  zoz2EmailAddress
}

<template>
  <div class="Zentrum" ref="zentrum">
    <v-container scroll-y>
      <!-- Pfeil zum scrollen -->
      <v-btn
        fab
        dark
        fixed
        bottom
        right
        v-scroll="onScroll"
        v-show="fab"
        color="indigo"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>

      <!-- Pfeil links (back) -->
      <v-btn fab dark small color="blue-grey" @click="$router.go(-1)">
        <v-icon dark fab>mdi-arrow-left-bold</v-icon>
      </v-btn>

      <!-- zentrum status flag -->
      <CenterStatusFlag :reviewState="this.center.review_state" />

      <v-spacer class="red my-3"></v-spacer>

      <!-- ================ zentrum header ================================ -->
      <v-card color="blue lighten-4">
        <v-card-title>
          <h2 class="center-name">
            {{ center.title }}
          </h2>
        </v-card-title>
        <v-card-subtitle>
          <span class="pr-2">Aktenzeichen: {{ center.aktenzeichen }}</span> |
          <span :class="'workflow-state-' + centerReviewState + ' pl-2'">
            Status: Der Antrag {{ centerReviewState }}.
          </span>
        </v-card-subtitle>
      </v-card>
      <!-- /zentrum -->

      <!-- @@@ tabs Strukkur @@@ -->
      <CenterFilterTabs
        :requirements="requirements"
        :ci="ci"
        :center="center"
        id="structureFilter"
        filterMethod="filterRequirementsOnStructure"
        bgColor="blue darken-4"
      />

      <!-- @@@ tabs Bereiche @@@ -->
      <CenterFilterTabs
        :requirements="requirements"
        :ci="ci"
        :center="center"
        id="subjectFilter"
        filterMethod="filterRequirementsOnSubject"
        bgColor="blue darken-3"
      />

      <!-- @@@ tabs status @@@ -->
      <CenterFilterTabs
        :requirements="requirements"
        :center="center"
        :ci="ci"
        id="statusFilter"
        filterMethod="filterRequirementsOnState"
        bgColor="blue darken-2"
      />

      <pre v-if="debugMode">
SERVER_MODE:  {{ serverMode }}
DEBUG_MODE:   {{ debugMode }}
LEN REQ:      {{ requirements.length }}
LastUpdate:   {{ requirementLastUpdate }}
centerPath:   {{ centerPath }}
permissions:  {{ permissions }}
center state: {{ center.review_state }}
roles:        {{ roles }}
ci:           {{ ci }} # 2: Chefinsp, 1: Insp.
isOnline:     {{ isOnline }}
ApplFinished  {{ applicationFinished }}

countDivergences      {{ countDivergences }}
evaluationFinished    {{ evaluationFinished }}

countRezert: {{ countRezert }}
user :       {{ user }}
SUMMARY:     {{evaluationFinished && countDivergences == 0 && countRezert == 0}}


      </pre>
      <template v-if="evaluationFinished && countDivergences == 0 && countRezert == 0">
        <v-expansion-panels class="action-panel my-4" tile accordion>
          <v-expansion-panel class="pa-0">
            <v-expansion-panel-header>
              <v-container>
                <v-row>
                  <v-col class="px-0">
                    <h2
                      title="Zusammenfassende Beschreibung des Prüfzentrum/Studienzentrale, Stärken und Schwächen, Entwicklungspotentiale, sonstige Anmerkungen"
                    >
                      Zusammenfassung
                    </h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row>
                  <v-col class="px-0">
                    <v-card flat class="">
                      <v-card-title>
                        <div style="word-break: break-word;">
                          Zusammenfassende Beschreibung des Prüfzentrum/Studienzentrale,
                          Stärken und Schwächen, Entwicklungspotentiale, sonstige
                          Anmerkungen
                        </div>
                      </v-card-title>
                      <v-card-text class="pb-2">
                        <v-form
                          v-if="
                            this.ci == 2 &&
                            permissions.includes(
                              'zoz2.content: Edit Requirement Chief Evaluation'
                            )
                          "
                        >
                          <v-textarea
                            v-model="center.summary"
                            class="white mb-3"
                            counter="2000"
                            label="Zusammenfassung"
                            outlined
                          ></v-textarea>
                          <v-btn name="submit" dark color="blue-grey" 
                            @click="saveSummary"
                            :loading="this.snackbar"
                            >Speichern
                          </v-btn>
                        </v-form>
                        <div v-else class="center summary text">
                          {{ center.summary }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <!-- ========= Aktionen ==================================== -->
      <v-expansion-panels class="action-panel my-4" tile accordion>
        <v-expansion-panel class="pa-0">
          <v-expansion-panel-header>
            <!-- Antrag einreichen -->
            <div
              v-if="
                this.ci == 0 &&
                permissions.includes('zoz2.content: Edit Requirement Statement')
              "
              class="close-actions py-4"
            >
              <v-btn
                :disabled="!applicationFinished"
                color="green"
                class="my-2 ml-2 white--text"
                @click="submitApplication((transition = 'submit'))"
                >Antrag einreichen
                <v-icon dark class="pl-3"> mdi-check-bold </v-icon>
              </v-btn>
            </div>

            <!-- Antrag erneut einreichen -->
            <div
              v-if="
                this.ci == 0 &&
                permissions.includes(
                  'zoz2.content: Edit Requirement Additional Statement'
                )
              "
              class="close-actions py-4"
            >
              <v-btn
                :disabled="!applicationFinished"
                color="green"
                class="my-2 ml-2 white--text"
                @click="submitApplication((transaction = 'reevaluate'))"
                >Antrag erneut einreichen
                <v-icon dark class="pl-3"> mdi-check-bold </v-icon>
              </v-btn>
            </div>

            <!-- Positive Bewertung -->
            <div
              v-if="
                this.ci == 2 &&
                permissions.includes('zoz2.content: Edit Requirement Chief Evaluation')
              "
              class="close-actions py-4"
            >
              <v-btn
                :disabled="
                  !evaluationFinished || countDivergences != 0 || countRezert != 0
                "
                color="green"
                class="my-2 ml-2 white--text"
                @click="dialog_cert = true"
                >Zert. empfohlen
                <v-icon dark class="pl-3"> mdi-check-bold </v-icon>
              </v-btn>
            </div>

            <!-- Negative Bewertung -->
            <div
              v-if="
                this.ci == 2 &&
                permissions.includes('zoz2.content: Edit Requirement Chief Evaluation')
              "
              class="close-actions py-4"
            >
              <v-btn
                :disabled="
                  !evaluationFinished || countDivergences != 0 || countRezert != 0
                "
                color="red"
                class="my-2 ml-2 white--text"
                @click="dialog_no_cert = true"
                >Zert. nicht empfohlen
                <v-icon dark class="pl-3"> mdi-alert </v-icon>
              </v-btn>
            </div>

            <!-- Bestätigungen erforderlich -->
            <!--
            <div 
              v-if="
                this.ci == 2 && permissions.includes('zoz2.content: Edit Requirement Chief Evaluation') &&
                countRezert > 0
                "
              class="close-actions py-4">
              <v-btn 
                
                class="my-2 ml-2 white--text rezert-confirm-request"
                @click="dialog_no_cert = true"
              >Bestätigungen erforderl.
                <v-icon dark class="pl-3">
                  mdi-alert
                </v-icon>
              </v-btn>
            </div>
            -->

            <!-- Weiteres Statement anfordern -->
            <div
              v-if="
                this.ci == 2 &&
                permissions.includes('zoz2.content: Edit Requirement Chief Evaluation')
              "
              class="close-actions py-4"
            >
              <v-btn
                :disabled="!evaluationFinished || countRezert != 0"
                color="orange"
                class="my-2 ml-2 white--text"
                @click="dialog_corrections = true"
                >Erfordert Korrekturen
                <v-icon dark class="pl-3"> mdi-plus </v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- ========= /Aktionen =================================== -->

      <!-- ======================== Anforderungen ===================================== -->
      <v-expansion-panels class="my-4" tile accordion>
        <v-expansion-panel
          class="pa-0"
          v-for="req in requirements"
          :key="req.UID"
          :data-url="req.UID"
        >
          <!-- @@@ faehnchen markierungen auf der linken seite @@@-->
          <RequirementMarker :req="req" :permissions="permissions" />
          <v-expansion-panel-header
            v-if="req.visible"
            :class="'blue-grey--text text--darken-4 ' + req['flag_colors'][3]"
            :data-rating_1="req['rating_1']"
            :data-rating_2="req['rating_2']"
          >
            <v-container>
              <v-row>
                <v-col class="pa-0 my-2">
                  <h3 v-if="isDivergence(req)">
                    {{ req.title }}
                    <i
                      v-if="
                        permissions.includes('zoz2.content: View Requirement Evaluation')
                      "
                      class="abweichung py-1 px-3"
                    >
                      Abweichung festgestellt
                    </i>
                  </h3>
                  <h3 v-else>{{ req.title }}</h3>
                </v-col>
              </v-row>
              <v-row v-if="req.pfad && req.pfad.length > 0">
                <v-col>
                  <v-spacer class="mt-1"></v-spacer>
                  <p class="mb-0 grey--text text--darken-1" v-html="req.pfad"></p>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col class="px-0">
                  <p ref="requirements" class="my-1 py-0" v-html="req.text"></p>
                </v-col>
              </v-row>

              <!-- ========= STELLUNGNAHME ZENTRUM ======================= -->
              <template
                v-if="
                  ci == 0 &&
                  permissions.includes('zoz2.content: Edit Requirement Statement')
                "
              >
                <v-row class="center-statements">
                  <v-col class="px-0 mx-0">
                    <v-card flat class="white">
                      <v-card-title> Stellungnahme </v-card-title>
                      <v-card-text>
                        <CenterStatement
                          :req="req"
                          :roles="roles"
                          :requirements="requirements"
                          :center="center"
                          :permissions="permissions"
                          :ci="ci"
                          :user="user"
                          @applicationReadyEvent="applicationFinished = true"
                          @applicationNotReadyEvent="applicationFinished = false"
                          @synchonizeServerEvent="synchronizeServer = true"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>

              <template v-else>
                <v-row class="center-statements">
                  <v-col class="px-0 mx-0">
                    <v-card flat class="blue-grey lighten-5" v-if="req.statement">
                      <v-card-title> Stellungnahme </v-card-title>
                      <v-card-text class="pb-2">
                        <span v-html="req.statement.replaceAll('\n', '<br />')" />
                        <div class="author">
                          {{ req["statement_author"] }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>

              <!-- ADDITIONAL STATEMENT -->
              <template
                v-if="
                  ci == 0 &&
                  permissions.includes(
                    'zoz2.content: Edit Requirement Additional Statement'
                  ) &&
                  (isDivergence(req) || isHinweis(req))
                "
              >
                <v-row class="center-statements">
                  <v-col class="px-0 mx-0">
                    <v-card flat class="white">
                      <v-card-title> Ergänzende Stellungnahme </v-card-title>
                      <v-card-text>
                        <CenterStatementAdditional
                          :req="req"
                          :roles="roles"
                          :requirements="requirements"
                          :center="center"
                          :permissions="permissions"
                          :ci="ci"
                          :user="user"
                          @applicationReadyEvent="applicationFinished = true"
                          @applicationNotReadyEvent="applicationFinished = false"
                          @synchonizeServerEvent="synchronizeServer = true"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>

              <template v-else>
                <v-row class="center-statements">
                  <v-col class="px-0 mx-0">
                    <v-card
                      flat
                      class="blue-grey lighten-5"
                      v-if="req.statement_additional"
                    >
                      <v-card-title> Ergänzende Stellungnahme </v-card-title>
                      <v-card-text class="pb-2">
                        <span
                          v-html="req.statement_additional.replaceAll('\n', '<br />')"
                        />
                        <div class="author">
                          {{ req["statement_author"] }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <!-- ========= /STELLUNGNAHME ZENTRUM ======================= -->

              <!-- ========= EVALU 1: AUDITOR ======================= -->
              <template
                v-if="permissions.includes('zoz2.content: View Requirement Evaluation')"
              >
                <v-row
                  class="center-evaluation"
                  v-if="req['rating_2'] != null && (ci == 1 || ci == 0)"
                >
                  <v-col class="px-0 mx-0">
                    <v-card flat class="blue lighten-5">
                      <!-- linker Farbstreifen -->
                      <v-card-title
                        :class="'bg-' + req['flag_colors'][2] + ' ' + req.notice_2"
                      >
                        Bewertung: {{ req["rating_2"] }}
                      </v-card-title>
                      <v-card-text
                        :class="'pb-2 bg-' + req['flag_colors'][2] + ' ' + req.notice_2"
                      >
                        <div
                          class="evaluation-tb"
                          v-if="req['evaluation_tb_2']"
                          v-html="req['evaluation_tb_2'].replaceAll('\n', '<br />')"
                        ></div>
                        <h3 :class="'notice notice-' + req['notice_2']">
                          {{ req["notice_2"] }}
                        </h3>
                        <div
                          :class="'evaluation eval-' + req['notice_2']"
                          v-if="req['evaluation_2']"
                          v-html="req['evaluation_2'].replaceAll('\n', '<br />')"
                        ></div>

                        <div class="author">{{ req["author_evaluation_2"] }}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="center-evaluation" v-if="ci == 1">
                  <v-col class="px-0 mx-0">
                    <v-card
                      flat
                      class="white"
                      v-if="
                        permissions.includes('zoz2.content: Edit Requirement Evaluation')
                      "
                    >
                      <!-- linker Farbstreifen -->
                      <v-card-title
                        :class="'bg-' + req['flag_colors'][1] + ' ' + req.notice_1"
                      >
                        Ihre Bewertung, {{ user["fullname"] }}: {{ req.rating_1 }}
                      </v-card-title>

                      <v-card-text
                        :class="'bg-' + req['flag_colors'][1] + ' ' + req.notice_1"
                      >
                        <CenterEvaluation
                          :req="req"
                          :center="center"
                          :roles="roles"
                          :requirements="requirements"
                          :permissions="permissions"
                          :user="user"
                          :ci="1"
                          @noRezertEvent="countRezert = 0"
                          @rezertEvent="countRezert = 1000"
                          @noDivergencesEvent="countDivergences = 0"
                          @divergencesEvent="countDivergences = 1000"
                          @evaluationReadyEvent="evaluationFinished = true"
                          @evaluationNotReadyEvent="evaluationFinished = false"
                          @synchonizeServerEvent="synchronizeServer = true"
                        />
                      </v-card-text>
                    </v-card>

                    <v-card v-else flat class="blue lighten-4">
                      <v-card-title
                        :class="'bg-' + req['flag_colors'][1] + ' ' + req.notice_1"
                      >
                        Ihre Bewertung, {{ user["fullname"] }}: {{ req["rating_1"] }}
                      </v-card-title>
                      <v-card-text
                        :class="'pb-2 bg-' + req['flag_colors'][1] + ' ' + req.notice_1"
                      >
                        <div
                          class="evaluation-tb"
                          v-if="req['evaluation_tb_1']"
                          v-html="req['evaluation_tb_1'].replaceAll('\n', '<br />')"
                        ></div>
                        <h3 :class="'notice notice-' + req['notice_1']">
                          {{ req["notice_1"] }}
                        </h3>
                        <div
                          :class="'evaluation eval-' + req['notice_1']"
                          v-if="req['evaluation_1']"
                          v-html="req['evaluation_1'].replaceAll('\n', '<br />')"
                        ></div>

                        <div class="author">{{ req["author_evaluation_1"] }}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <!-- ========= /EVALU 1: AUDITOR ======================= -->

              <!-- ========= EVALU 2: CHIEFAUDITOR ======================= -->
              <template
                v-if="permissions.includes('zoz2.content: View Requirement Evaluation')"
              >
                <v-row
                  class="center-evaluation"
                  v-if="req['rating_1'] != null && (ci == 2 || ci == 0)"
                >
                  <v-col class="px-0 mx-0">
                    <!-- linker Farbstreifen -->
                    <v-card flat class="blue lighten-5">
                      <v-card-title
                        :class="'bg-' + req['flag_colors'][1] + ' ' + req.notice_1"
                      >
                        Bewertung: {{ req["rating_1"] }}
                      </v-card-title>
                      <v-card-text
                        :class="'pb-2 bg-' + req['flag_colors'][1] + ' ' + req.notice_1"
                      >
                        <div
                          class="evaluation-tb"
                          v-if="req['evaluation_tb_1']"
                          v-html="req['evaluation_tb_1'].replaceAll('\n', '<br />')"
                        ></div>
                        <h3 :class="'notice notice-' + req['notice_1']">
                          {{ req["notice_1"] }}
                        </h3>
                        <div
                          :class="'evaluation eval-' + req['notice_1']"
                          v-if="req['evaluation_1']"
                          v-html="req['evaluation_1'].replaceAll('\n', '<br />')"
                        ></div>

                        <div class="author">{{ req["author_evaluation_1"] }}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="center-evaluation" v-if="ci == 2">
                  <v-col class="px-0 mx-0">
                    <v-card
                      flat
                      class="white"
                      v-if="
                        permissions.includes(
                          'zoz2.content: Edit Requirement Chief Evaluation'
                        )
                      "
                    >
                      <v-card-title
                        :class="'bg-' + req['flag_colors'][2] + ' ' + req.notice_2"
                      >
                        Ihre Bewertung, {{ user["fullname"] }}:
                      </v-card-title>
                      <v-card-text
                        :class="'bg-' + req['flag_colors'][2] + ' ' + req.notice_2"
                      >
                        <CenterEvaluation
                          :req="req"
                          :center="center"
                          :roles="roles"
                          :requirements="requirements"
                          :permissions="permissions"
                          :user="user"
                          :ci="2"
                          @noRezertEvent="countRezert = 0"
                          @rezertEvent="countRezert = 1000"
                          @noDivergencesEvent="countDivergences = 0"
                          @divergencesEvent="countDivergences = 100"
                          @evaluationReadyEvent="evaluationFinished = true"
                          @evaluationNotReadyEvent="evaluationFinished = false"
                          @synchonizeServerEvent="synchronizeServer = true"
                        />
                      </v-card-text>
                    </v-card>
                    <v-card flat v-else class="blue lighten-4">
                      <v-card-title
                        :class="'bg-' + req['flag_colors'][2] + ' ' + req.notice_2"
                      >
                        Ihre Bewertung, {{ user["fullname"] }}: {{ req.rating_2 }}
                      </v-card-title>
                      <v-card-text
                        :class="'pb-2 bg-' + req['flag_colors'][2] + ' ' + req.notice_2"
                      >
                        <div
                          class="evaluation-tb"
                          v-if="req['evaluation_tb_2']"
                          v-html="req['evaluation_tb_2'].replaceAll('\n', '<br />')"
                        ></div>
                        <h3 :class="'notice notice-' + req['notice_2']">
                          {{ req["notice_2"] }}
                        </h3>
                        <div
                          :class="'evaluation eval-' + req['notice_2']"
                          v-if="req['evaluation_2']"
                          v-html="req['evaluation_2'].replaceAll('\n', '<br />')"
                        ></div>

                        <div class="author">{{ req["author_evaluation_2"] }}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <!-- ========= /EVALU 2: CHIEFAUDITOR ======================= -->
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- /requirements -->

      <!-- ========= LEGENDE ===================================== -->
      <v-expansion-panels class="action-panel my-4" tile accordion>
        <v-expansion-panel class="pa-0">
          <v-expansion-panel-header>
            <h3>Legende</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col>
                  <div class="erfuellt">
                    <h5>Erfüllt</h5>
                    <div><i class="notice-OhneAnmerkung px-2 mr-3"></i>Erfüllt</div>
                    <div><i class="notice-Anmerkung px-2 mr-3"></i>mit Anmerkung</div>
                  </div>
                </v-col>
                <v-col>
                  <div class="nicht erfüllt">
                    <h5>Nicht erfüllt</h5>
                    <div><i class="notice-Feststellung px-2 mr-3"></i> Feststellung</div>
                    <div><i class="notice-Hinweis px-2 mr-3"></i> Hinweis</div>
                    <div>
                      <i class="notice-Abweichung px-2 mr-3"></i>
                      <span class="red--text">Abweichung</span>
                    </div>
                  </div>
                  <div class="">
                    <h5>Rezertifizierung</h5>
                    <div>
                      <i class="notice-Rezertifizierung px-2 mr-3"></i> Bestätigung
                      erforderlich
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <h5>Stellungnahme</h5>
                  <div><i class="notice-Unbearbeitet px-2 mr-3"></i> Unbearbeitet</div>
                  <div><i class="notice-Bearbeitet px-2 mr-3"></i> Bearbeitet</div>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- ========= LEGENDE ===================================== -->

      <!-- ========= DOWNLOADS ==================================== -->
      <CenterDownloads
        title="Dokumente"
        location="repository"
        :downloads="downloads"
        :center="center"
        :permissions="permissions"
        :roles="roles"
      />
      <!-- ========= /DOWNLOADS =================================== -->

      <!-- ========= REPORTS ==================================== -->
      <CenterDownloads
        title="Reports"
        location="reports"
        :downloads="reports"
        :center="center"
        :permissions="permissions"
      />
      <!-- ========= /REPORTS =================================== -->

      <!-- Message Box - - - - - - - - - -->
      <div
        id="portal-message"
        @mouseleave="showMessage = false"
        @click="showMessage = false"
        class="center-message ma-0 pa-0"
      >
        <v-alert
          class="ma-1 pa-8"
          type="info"
          color="light-green darken-1"
          dark
          icon="mdi-checkbox-marked"
          v-if="message && showMessage"
        >
          {{ message }}
        </v-alert>
      </div>

      <!-- DIALOG BOX  Zertifizierung empfohlen -->
      <v-dialog v-model="dialog_cert" persistent max-width="440">
        <v-card>
          <v-card-title class="text-h6"> Zertifizierung empfohlen? </v-card-title>
          <v-card-text>
            Achtung: Sind Sie sicher, dass Sie die Zertifizierung jetzt empfehlen möchten?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-0" dark @click="dialog_cert = false">
              Nein, nicht sicher
            </v-btn>
            <v-btn color="green darken-1" dark @click="submitEvaluation('approve')">
              Ja, absenden!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG BOX  Zertifizierung nicht empfohlen -->
      <v-dialog v-model="dialog_no_cert" persistent max-width="440">
        <v-card>
          <v-card-title class="text-h6"> Zertifizierung nicht empfohlen? </v-card-title>
          <v-card-text>
            Achtung: Sind Sie sicher, dass Sie die Zertifizierung nicht empfehlen möchten?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-0" dark @click="dialog_no_cert = false">
              Nein, nicht sicher
            </v-btn>
            <v-btn color="red darken-1" dark @click="submitEvaluation('reject')">
              Ja, absenden!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG BOX  Erfordert Korrekturen -->
      <v-dialog v-model="dialog_corrections" persistent max-width="440">
        <v-card>
          <v-card-title class="text-h6"> Korrekturen anfordern? </v-card-title>
          <v-card-text>
            Achtung: Sind Sie sicher, dass Sie Korrekturen anfordern möchten?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-0" dark @click="dialog_corrections = false">
              Nein, nicht sicher
            </v-btn>
            <v-btn
              color="orange darken-1"
              dark
              @click="submitEvaluation('request_amendment')"
            >
              Ja, absenden!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- mini message box -->
    <div class="text-center top">
      <v-snackbar 
        color="green"
        v-model="snackbar"
        class="action-message save"
        :timeout="timeout"
      >
        {{ clickMessage }}
      </v-snackbar>
    </div>
    <!-- /message box -->


  </div>
</template>

<script>
import Vue from "vue";
import { router } from "../router";
import CenterStatement from "@/components/CenterStatement";
import CenterStatementAdditional from "@/components/CenterStatementAdditional";
import CenterEvaluation from "@/components/CenterEvaluation";
import CenterStatusFlag from "@/components/CenterStatusFlag";
import CenterFilterTabs from "@/components/CenterFilterTabs";
import CenterDownloads from "@/components/CenterDownloads";
import RequirementMarker from "@/components/RequirementMarker";

import {
  PloneRestApi,
  // ploneInstancePath,
  serverMode,
  debugMode,
  centerReviewStates,
  alertMessages,
  // shortEvaluations,
  // cacheCenters,
  zozDB,
} from "@/config";

const zozZentrumSearchUid = "/@search?portal_type=Zentrum&fullobjects=1&UID=";

const CLICKMESSAGES = {
  'saved': 'Ihre Zusammenfassung wurde gespeichert.',
}

/* --- local database ---- */
// import Localbase from 'localbase'
// const zozDB = new Localbase('zozDB')
zozDB.config.debug = true;

export default {
  components: {
    CenterStatement,
    CenterStatementAdditional,
    CenterEvaluation,
    CenterStatusFlag,
    CenterFilterTabs,
    CenterDownloads,
    RequirementMarker,
  },

  data() {
    return {
      center: { summary: "" },
      centerPath: "",
      centerReviewStates: centerReviewStates,
      centerReviewState: "", //centerReviewStates['draft'],

      requirements: [],
      requirementLastUpdate: new Date(),

      downloads: [],
      reports: [],
      sumRequirements: 0,
      roles: [],
      permissions: [],
      // ci: 0: Center, 1: Inspector, 2: Chiefinspector
      ci: 0,

      evaluationFinished: false,
      applicationFinished: false,
      synchronizeServer: false,

      isReadyToFinishEvaluation: false,
      noDivergenceDetected: false,
      countDivergences: 0,
      countRezert: 0,

      fab: false,
      // #LM???? showBackOnline: false,
      message: "",
      showMessage: false,

      // #### USER ##########
      user: this.$route.params.user, //// Object, // zozDB.collection('user').get(),
      state: this.$route.params.item.review_state,

      loggedIn: false,
      // -- short message
      pathSlicer: 4,

      serverMode: serverMode,
      debugMode: JSON.parse(debugMode), // true/false-String,

      isOnline: navigator.onLine,

      // dialog box
      dialog_cert: false,
      dialog_no_cert: false,
      dialog_corrections: false,
      //
      snackbar: false,
      timeout: 2000,
      clickMessage: '',
    };
  },

  /* --- WATCH ----------------------- */
  watch: {
    synchronizeServer: function (val) {
      if (val == true) {
        this.sendData2Server();
        //  this.requirementLastUpdate = new Date()
        this.synchronizeServer = false;
      }
    },
  },

  /* --- COMPUTED ----------------------- */
  computed: {},

  /* --- CREATED ----------------------- */
  created: function () {
    // --- sorgt dafür, dass die Seite nur für angemeldete benutzer sichtbar ist.
    if (Vue.cookie.get("__ac")) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
      if (router.history.current.name !== "Login") {
        router.push({ name: "Login" });
      }
    }
  },

  /* --- METHODS ----------------------- */
  methods: {
    /* --- testen, ob ee bei der Anforderung eine abweichung gibt ---- */
    isDivergence: function (req) {
      if (req.notice_1 == "Abweichung" || req.notice_2 == "Abweichung") {
        return true;
      }
      return false;
    },
    /* --- testen, ob hinweis in der anforderung vorhanden --- */
    isHinweis: function (req) {
      if (req.notice_1 == "Hinweis" || req.notice_2 == "Hinweis") {
        return true;
      }
      return false;
    },
    /* --- testen, ob eval erneut gespeicht werden muss --- */
    isRezert: function (req) {
      if (
        req["author_evaluation_" + this.ci] &&
        req["author_evaluation_" + this.ci] != this.user["fullname"]
      ) {
        return true;
      }
      return false;
    },

    /* --- how many statments are finished? -- */
    countStatements: function () {
      let counter = 0;
      for (let i = 0; i < this.requirements.length; i++) {
        console.log("this.requirements[i].statement: " + this.requirements[i].statement);
        if (this.requirements[i].statement) {
          counter++;
        }
      }
      return counter;
    },

    /* --- check if evaluation can be submitted ----- */
    /* es reicht, wenn ein Inspektor eine Evaluierung vorgenommen hat
    */
    checkIfEvaluationIsFinished: function () {
      for (const req of this.requirements) {
        console.log("---------------------------------------------------");
        console.log(req);
        console.log("R1: " + req.rating_1 + "   |   R2: " + req.rating_2);
        if (!(req.rating_1 || req.rating_2)) {
          return false;
        }
      }
      return true;
    },

    /* --- online/offline check ----- */
    updateOnlineStatus(e) {
      const { type } = e;
      this.isOnline = type === "online";
      if (this.isOnline) {
        this.synchronizeServer = true;
      }
    },

    checkIfNotEmpty: function () {
      // DUMMY TODO
    },

    saveSummary: function () {
      /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
      /*      Send Data to Plone Server                             */
      /* -+-+-+-+-+-+-+-+-+-+-+-+-+-+--+-+-+-+-+-+-+-+-+-+-+-+-+-+- */

      console.log("########### this.center.summary: ", this.center.summary);


      PloneRestApi(this.centerPath, {
        method: "PATCH",
        data: { summary: this.center.summary },
      })
        .then((response) => {
          console.log(response);

          this.clickMessage = CLICKMESSAGES['saved']
          this.snackbar = true
        })
        .catch((e) => {
          console.log(e);
          alert(e);
          console.log("[AupdateSummary] Server Fehler: ");
        });
    },

    /* --- Synchonisierung mit Plone ----- */
    sendData2Server: function () {
      zozDB
        .collection("requirements")
        .get()
        .then((requirements) => {
          /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
          /*      Send Data to Plone Server                             */
          /* -+-+-+-+-+-+-+-+-+-+-+-+-+-+--+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
          for (let i = 0; i < requirements.length; i++) {
            const req = requirements[i];

            if (req["timestamp"].getTime() > this.requirementLastUpdate.getTime()) {
              // --- get requirement path ---------------
              const reqPath =
                "/" + req["@id"].split("/").slice(this.pathSlicer).join("/");
              console.log(reqPath);

              PloneRestApi(reqPath, {
                method: "PATCH",
                data: req,
              })
                .then((response) => {
                  console.log(response);
                  // --- Datum neu setzen ----
                  this.requirementLastUpdate = new Date();
                })
                .catch((e) => {
                  console.log(e);
                  console.log("[sendData2Server] Server Fehler: " + reqPath);
                });
            }
          }
          /* -+-+-+-+-+-+-+-+-+-  /RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
        })
        .catch((e) => {
          console.error(e);
        });
    },

    /* --- Evaluierung abschließen --- */
    submitEvaluation: function (wfTransition) {
      /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
      /*          Submit Application                                */
      /* -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+ */
      this.dialog_no_cert = false;
      this.dialog_cert = false;
      this.dialog_corrections = false;

      PloneRestApi(this.centerPath + "/@workflow/" + wfTransition, { method: "POST" })
        .then((response) => {
          console.log(response);
          if (wfTransition == "approve") {
            this.message = alertMessages["CERTIFICATION_APPROVED"];
          } else if (wfTransition == "reject") {
            this.message = alertMessages["CERTIFICATION_DENYIED"];
          } else if (wfTransition == "request_amendment") {
            this.message = alertMessages["REQUEST_ADDITIONAL_STATMENTS"];
          }
          // --- display message -------
          this.showMessage = true;

          setTimeout(function () {
            this.applicationFinished = false;
            this.evaluationFinished = false;
            window.location.reload(true);
          }, 1000);
        })
        .catch((e) => {
          console.log(e);
          console.error("Server-Problem: " + this.centerPath);
        });
    },

    testAction: function (transaction = "gaga") {
      alert(this.centerPath + "/@workflow/" + transaction);
    },

    /* --- Bewerbung abschließen --- */
    submitApplication: function (transaction = "submit") {
      /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
      /*          Submit Application                                */
      /* -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+ */
      PloneRestApi(this.centerPath + "/@workflow/" + transaction, { method: "POST" })
        .then((response) => {
          console.log(response);

          // --- display message -------
          this.message = alertMessages["APPLICATION_SUBMIT"];
          this.showMessage = true;

          setTimeout(function () {
            this.applicationFinished = false;
            window.location.reload(true);
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
          console.error("Server-Problem: " + this.centerPath);
        });
    },

    /* -- statement flag --------- */
    getStatementMarker: function (statement = "") {
      if (statement.length > 0) {
        return "ok";
      } else {
        return "idle";
      }
    },

    /* --- Scroll to top --------------------------- */
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },
  },

  /* ---------- before  ----------------- */
  beforeCreate() {
    // -- get user form localbase ---
    /*
    zozDB.collection('user').get()
    .then(user => {
      if(user) {
        console.debug(user)
        this.user = {
          username: user[0].username,
          fullname: user[0].fullname,
          email: user[0].userEmail
        }
      }
    })
    */
  },

  /* --- MOUNTED ------------------------ */
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);

    const uid = this.$route.params.uid;
    const item = this.$route.params.item;

    //const user = this.$route.params.user
    //console.debug("################################## USER PARA ########  " + user['fullname'])

    // Bei browser reload muss das Center aus CenterListing neu geladen werden!
    if (!item) {
      console.debug("Bei Browser-Reload muss die Liste der Zentren neu geladen werden!");
      router.push({ name: "Zentren" });
    }

    // ######################### WICHTIG ##############################
    console.log("----> ServerMode: ", serverMode);
    if (serverMode == "PRODUCTION") {
      this.pathSlicer = 3;
    } else {
      this.pathSlicer = 3; // wenn über app.zoz2.de.local !!! ansonsten 4
    }
    console.log("############# PATH_SLICER:   ", this.pathSlicer);

    /* --- add auth header --- */

    /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
    PloneRestApi.defaults.headers["Authorization"] = "Bearer " + this.$cookie.get("__ac");

    // PloneRestApi(search_uri, {cache: cacheCenters} )
    console.log("CENTER SEARCH:   " + zozZentrumSearchUid + uid);

    /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
    /*            GET center data                                 */
    /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
    // PloneRestApi(zozZentrumSearchUid + uid, {cache: cacheCenters} )

    PloneRestApi(zozZentrumSearchUid + uid)
      .then((response) => {
        if (response.data.items.length === 1) {
          // --- center: /items[0] ----
          this.center = response.data.items[0];
          this.centerReviewState = this.centerReviewStates[this.center.review_state];

          console.log("====> ITEM URI:      " + this.center["@id"]);
          console.log("====> ITEM SUMMARY:  ", this.center["summary"]);
          console.log(
            "====> CENTERPATH:    " +
              this.center["@id"].split("/").slice(this.pathSlicer).join("/")
          );

          // --- get center path ---------------
          const centerPath =
            "/" + this.center["@id"].split("/").slice(this.pathSlicer).join("/");
          console.log("centerPath: " + centerPath);
          this.centerPath = centerPath;

          /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
          /*          Get the Center data                               */
          /* -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+ */
          PloneRestApi(centerPath + "?expand=requirements").then((resp) => {
            if (resp.data["@components"].requirements) {
              console.log("--------- Lese Anforderungen vom Server -----------------");
              console.log(resp.data["@components"].requirements.items);
              console.log(resp.data["@components"].requirements.roles);
              console.log(resp.data["@components"].requirements.permissions);
              this.requirements = resp.data["@components"].requirements.items;
              this.requirementLastUpdate = new Date();
              this.roles = resp.data["@components"].requirements.roles;
              this.permissions = resp.data["@components"].requirements.permissions;
              if (this.roles.includes("ChiefInspector")) {
                this.ci = 2; // = ChiefInspector
              } else if (this.roles.includes("Inspector")) {
                this.ci = 1; // = Inspector
              } else {
                this.ci = 0;
              }
              console.log("--------- /Lese Anforderungen vom Server -----------------");

              /* --------- LOCALBASE ---------------------------------------------------- */
              /*      schreibe requirements in Localbase                                  */
              /* ------------------------------------------------------------------------ */
              console.log(
                "---- Schreibe alle Anforderungen in Localbase -----------------"
              );

              // *** #LM: Korrektur 2023-11-10 -----
              // --------- Requirements muessen sortiert werden

              let reqs = this.requirements;

              let nlist = [];
              for (let i = 0; i < reqs.length; i++) {
                nlist = reqs[i]["title"].replace(" ", ".");
                // nlist = nlist.split('.') // #LM Achtung: bei '.'-ten im Titel funktioniert das nicht
                // daher nur die ersten ca. 14 Stellen berücksichtigen ?
                nlist = nlist.slice(0, 14).split(".");
                nlist.pop();
                while (nlist.length < 5) {
                  nlist.push("00");
                }
                let sortindex = "";
                for (let x = 0; x < nlist.length; x++) {
                  let num = nlist[x];
                  while (num.length < 2) num = "0" + num;
                  sortindex = sortindex + num;
                }
                reqs[i]["sortindex"] = parseInt(sortindex);
              }
              this.requirements = reqs.sort(function (a, b) {
                let x = a.sortindex;
                let y = b.sortindex;
                if (x > y) {
                  return 1;
                }
                if (x < y) {
                  return -1;
                }
                return 0;
              });
              // -------- /Requirements muessen sortiert werden

              for (let i = 0; i < this.requirements.length; i++) {
                let req = this.requirements[i];

                // --- get flagColors ---
                let flagColors = ["", "", "", ""];
                // req.flag_colors = flagColors

                for (let r = 0; r < req.textitems.length; r++) {
                  // this.setFlagColors
                  if (req.textitems[r].text == req["rating_1"]) {
                    flagColors[1] = req.textitems[r].flag_color;
                  }

                  if (req.textitems[r].text == req["rating_2"]) {
                    flagColors[2] = req.textitems[r].flag_color;
                  }
                }

                // rezert #############################################
                if (this.ci != 3) {
                  // nur inspektoren !
                  // console.debug(" STATE: " + this.state)
                  if (this.state.includes("evaluation")) {
                    if (
                      req["author_evaluation_" + this.ci] &&
                      req["author_evaluation_" + this.ci] != this.user["fullname"]
                    ) {
                      // console.debug("req: " + req['author_evaluation_' + this.ci] + ': ' + req.title)
                      // console.debug("CENTER AUTOR:   " + req['author_evaluation_' + this.ci])
                      // console.debug("CENTER CURRENT: " + this.user['fullname'] )

                      flagColors[3] = "rezert";
                    } else {
                      flagColors[3] = "";
                    }
                  }
                }
                // /rezert ############################################

                req.flag_colors = flagColors;
                // console.debug(">>>> COLOR_FLAGS:   " + req.flag_colors )

                // write to db
                zozDB
                  .collection("requirements")
                  .add(
                    {
                      "@id": req["@id"],
                      title: req.title,
                      UID: req.UID,
                      text: req.text,

                      statement: req.statement,
                      statement_additional: req.statement_additional,
                      statement_author: this.user["fullname"],

                      rating_1: req.rating_1,
                      evaluation_1: req.evaluation_1,
                      evaluation_tb_1: req.evaluation_tb_1,
                      notice_1: req.notice_1,
                      author_evaluation_1: req.author_evaluation_1,

                      rating_2: req.rating_2,
                      evaluation_2: req.evaluation_2,
                      evaluation_tb_2: req.evaluation_tb_2,
                      notice_2: req.notice_2,
                      author_evaluation_2: req.author_evaluation_2,

                      // extra data ---
                      timestamp: this.requirementLastUpdate,
                      username: this.user["username"],
                      center: {
                        title: this.center.title,
                        UID: this.center.UID,
                      },
                      flag_colors: flagColors,
                    },
                    req.UID
                  )

                  .then((response) => {
                    console.log(response);
                  })

                  .catch((e) => {
                    console.log("OFFLINE: Server nicht erreichbar!");
                    console.error(e);
                  });
              }
              console.log(
                "---- /Schreibe alle Anforderungen in Localbase -----------------"
              );
              /* --- /schreibe requirements in Localbase ---- */

              // is the application already ready to submit?
              if (this.countStatements() == this.requirements.length) {
                this.applicationFinished = true;
              }

              // count divergences (Abweichungen)
              this.countDivergences = 0;
              for (let i = 0; i < this.requirements.length; i++) {
                const req = this.requirements[i];
                // console.log("NOTIZEN: " + req.notice_1 + ', ' + req.notice_2)
                if (req.notice_1 == "Abweichung" || req.notice_2 == "Abweichung") {
                  this.countDivergences++;
                }
              }

              // count rezert
              this.countRezert = 0;
              for (let i = 0; i < this.requirements.length; i++) {
                const req = this.requirements[i];
                if (
                  req["author_evaluation_" + this.ci] &&
                  req["author_evaluation_" + this.ci] != this.user["fullname"]
                ) {
                  this.countRezert++;
                }
                console.debug("this.countRezert " + this.countRezert);
              }

              // is the evaluation ready to submit?
              this.evaluationFinished = this.checkIfEvaluationIsFinished();
            }
          });

          /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
          /*          Get the Center Downloads                          */
          /* -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+ */
          PloneRestApi(centerPath + "/@@getDownloads?container=repository")
            .then((resp) => {
              if (resp.data) {
                //console.log(resp.data)
                this.downloads = resp.data.items;
              }
            })
            .catch((e) => {
              console.error(e);
            }); /* catch */

          /* -+-+-+-+-+-+-+-+-+-  RESTAPI -+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
          /*          Get the Center Reports                            */
          /* -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+ */
          PloneRestApi(centerPath + "/@@getDownloads?container=reports")
            .then((resp) => {
              if (resp.data) {
                console.log(resp.data);
                this.reports = resp.data.items;
              }
            })
            .catch((e) => {
              console.error(e);
            }); /* catch */
        } /* if */
      }) /* then */
      .catch((e) => {
        console.error(e);
      }); /* catch */
  },

  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>

<style scope lang="scss">
.image-richtext.image-right {
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
  max-width: 30%;
}
.image-richtext.image-left {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  max-width: 30%;
}

.center-name {
}

.abweichung {
  background-color: red;
  color: white;
  float: right;
  padding: 3px 20px;
  white-space: nowrap;
}

.author {
  padding-top: 20px;
  font-style: italic;
  color: #666;
  text-align: right;
  width: 100%;
}

.bg-undefined {
  border-left: 10px solid #f3f3f3;
}
.bg-green {
  border-left: 10px solid rgb(153, 255, 0);
}
.bg-green.Anmerkung {
  border-left: 10px solid rgb(100, 220, 0); /* */
}
.bg-yellow {
  border-left: 10px solid rgb(252, 255, 56);
}
.bg-orange {
  border-left: 10px solid rgb(255, 203, 106);
}
.bg-red {
  border-left: 10px solid rgb(255, 150, 0); /* rot ist nicht ganz rot :-) */
}
.bg-darkgrey {
  border-left: 10px solid rgb(95, 95, 95);
}
.bg-red.Feststellung {
  border-left: 10px solid rgb(255, 220, 0);
}
.bg-red.Hinweis {
  border-left: 10px solid rgb(255, 150, 0);
}
.bg-red.Abweichung {
  border-left: 10px solid rgb(255, 0, 0); /* total rot */
}

.bg-grey {
  border-left: 10px solid grey;
}

.close-actions {
  float: right;
}

.v-expansion-panels {
  z-index: 0;
}

.evaluation-tb {
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  margin: 0 0 20px 0;
}

.notice {
  padding: 5px 10px;
  margin: 0 0 0px 0;
  color: white;
  width: 240px;
  clear: both;
}
.notice-OhneAnmerkung {
  background-color: rgb(153, 255, 0);
}
.notice-Anmerkung {
  background-color: rgb(100, 175, 0);
}
.notice-Feststellung {
  background-color: rgb(255, 220, 0);
}
.notice-Hinweis {
  background-color: rgb(255, 150, 0);
}
.notice-Rezertifizierung {
  background-color: lighten(blue, 20%);
}
.notice-Abweichung {
  background-color: rgb(255, 0, 0);
}
.notice-Unbearbeitet {
  background-color: #f3f3f3;
}
.notice-Bearbeitet {
  background-color: rgb(180, 180, 180);
}
.notice-Begründung {
  background-color: rgb(120, 120, 120);
}

.evaluation {
  padding: 5px 10px;
  background-color: #f5f6f7;
}
.eval-Anmerkung {
  border-top: 1px solid rgb(100, 220, 0);
}
.eval-Feststellung {
  border-top: 1px solid rgb(255, 220, 0);
}
.eval-Hinweis {
  border-top: 1px solid rgb(255, 150, 0);
}
.eval-Abweichung {
  border-top: 1px solid rgb(255, 0, 0);
}

.rezert {
  background-color: lighten(blue, 45%);
  border-right: 15px solid lighten(blue, 20%);
}
.rezert-false {
  background-color: transparent;
}
.rezert-confirm-request {
  background-color: lighten(blue, 20%) !important;
}

.action-message.save {
  bottom: 50%
}
.center.summary.text {
  white-space: preserve-breaks;
  background-color: #f3f4f5;
  padding: 10px;
}
</style>

<!-- 
===============================================================
Die Marker werden an den Anforderungen links angezeigt

===============================================================
-->
<template>
  <div class="marker" v-if="req.visible">
    
    <!-- Center -->
    <div 
      :class="'statement-marker-'+ String(req['statement'] != null)" />

    <template 
      v-if="permissions.includes('zoz2.content: View Requirement Evaluation')">
      
      <!-- 1. Auditor -->
      <div v-if="req['flag_colors'][1] != null"
        :title="'1. Auditor: ' + req.rating_1 + ': ' + req.notice_1"
        :class="'marker-' + req['flag_colors'][1] + ' ' + req.notice_1" />
      <div v-else class="marker" />
      
      <!-- 2. Auditor -->
      <div v-if="req['flag_colors'][2] != null"
        :title="'2. Auditor: ' + req.rating_2 + ': ' + req.notice_2"
        :class="'marker-' + req['flag_colors'][2] + ' ' + req.notice_2" />

      <!-- ReZert -->
      <div v-if="req['flag_colors'][3] != null"
        title="Neubewertung erforderlich"
        :class="'marker-' + req['flag_colors'][3] + ' Bestaetigung'" />

      <div v-else class="marker" />
    </template>


  </div>
</template>





<script>

import { 
  debugMode
} from '@/config'


export default {

  props: [
    'req',
    'permissions'
  ],

  data: function() {
    return {
      reZert: false,
      trueRed: '#f00',
      debugMode: JSON.parse(debugMode), // true/false-String,
    }
  },

  methods: {
  }  
}
</script>






<style scope lang="scss">

.marker {
  position: absolute;
  margin-left: -20px;
  width: 20px;
  height: 20px;
  padding: 0px 0px 2px 0;
  > div {
    background-color: transparent;
    height: 20px;
    width: 20px;
    margin-bottom: 2px;
    border-radius: 5px 0 0 5px;
  }
 .marker-rezert {
                    display: none;
    position: absolute;
    top: 0;
    margin-left: 20px;
    height: 65px;
    border-radius: 0;
  }
}


.marker .marker-undefined {
  background-color: #f3f3f3;
}
.marker .marker-green {
  background-color: rgb(153, 255, 0);
}
.marker .marker-green.Anmerkung {
  background-color: rgb(100, 220, 0);
}
.marker .marker-yellow {
  background-color: rgb(252, 255, 56);
}
.marker .marker-orange {
  background-color: rgb(255, 203, 106);
}
.marker .marker-darkgrey {
  background-color: rgb(95, 95, 95);
}
.marker .marker-magenta {
  background-color: rgb(255, 0, 255);
}


.marker .marker-red {
  background-color: rgb(255, 171, 46); /* nicht rot, da nur abgelehnt, wenn es eine Abweichung gibt */
}
.marker .marker-red.Feststellung {
  background-color: rgb(255, 220, 0);
}
.marker .marker-red.Hinweis {
  background-color: rgb(255, 150, 0); 
}
.marker .marker-red.Abweichung {
  background-color: rgb(255, 0, 0); 
}

.marker .marker-magenta {
  background-color: rgb(255, 0, 255); 
}

.marker .marker-rezert {
  background-color: lighten(blue, 20%); 
}

.marker .marker-grey {
  background-color: grey;
}   
.marker .statement-marker-true {
  background-color: rgb(180, 180, 180);
}
.marker .statement-marker-false {
  background-color: #f3f3f3;
}

</style>
<template>
    <div>
      <span class="small">© 2023 Deutsche Gesellschaft für Hämatologie und Medizinische Onkologie e. V.</span>
      <br />
      <span class="tiny">Software-Version:  {{ softwareVersion }}</span>
      <span class="tiny">Projektstatus: {{ projectStatus }}</span>
      <span class="tiny">ServerMode: {{ serverMode }}</span>
    </div>
</template>

<script>
  import Vue from 'vue'
  import {
    softwareVersion, 
    projectStatus,
    serverMode,
    debugMode
  } from '@/config'

  export default {
    name: 'Footer',


    data () {
      return {
        vueVersion: Vue.version,
        softwareVersion: softwareVersion,
        projectStatus: projectStatus,
        serverMode: serverMode,
        debugMode: JSON.parse(debugMode) // true/false-String,
      }
    },

    mounted() {
      console.log('SW Status:' + softwareVersion)
      console.log('PR Status: ' + projectStatus)
    }
  }
</script>

<style scope>
.small {
  font-size: 86%;
  color: #ccc;
  padding-left: 100px;
}
.tiny {
  font-size: 70%;
  color: #999;
  padding-left: 100px;
}
</style>

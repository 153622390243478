var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Zentren"},[(_vm.debugMode)?_c('pre',[_vm._v("  USER:   "+_vm._s(_vm.user)+"\n")]):_vm._e(),_c('v-container',[_c('v-expansion-panels',{staticClass:"my-4",attrs:{"tile":"","accordion":""}},_vm._l((_vm.items),function(item){return _c('v-expansion-panel',{key:item.id,staticClass:"py-4 px-2 my-1"},[_c('v-expansion-panel-header',{attrs:{"data-UID":item.UID,"color":"white"}},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('v-chip',{class:'text-uppercase font-weight-bold center-list center-label-' + 
              item.review_state,attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.centerReviewStates[item.review_state])+" ")])],1),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-subtitle',{staticClass:"px-0 py-2 mx-0"},[_c('div',[_vm._v(_vm._s(item.description.data))])]),_c('v-card-text',{staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"az my-2"},[_c('h4',[_vm._v("Aktenzeichen: "+_vm._s(item.aktenzeichen))])]),_c('div',{staticClass:"modified my-2"},[_c('span',[_vm._v("Stand: "+_vm._s(_vm.niceDate(item.modified)))])]),_c('div',{staticClass:"description my-2"},[_c('h4',[_vm._v("Beschreibung:")]),(item.beschreibung_des_zentrums)?_c('span',{domProps:{"innerHTML":_vm._s(item.beschreibung_des_zentrums.data)}}):_vm._e()]),_c('address',{staticClass:"address my-2"},[_c('h4',[_vm._v("Adresse:")]),_vm._v(" "+_vm._s(item.adresse)+" "),_c('br'),_vm._v(" "+_vm._s(item.plz)+" - "+_vm._s(item.ort)+" ")]),_c('div',{staticClass:"contact my-2"},[_c('h4',[_vm._v("Kontaktpersonen: ")]),(item.kontaktpersonen)?_c('span',{domProps:{"innerHTML":_vm._s(item.kontaktpersonen.replace('\n','<br />'))}}):_vm._e()]),_c('div',{staticClass:"inspektors my-2"},[_c('h4',[_vm._v("Zuständige Inspektoren: ")]),(item.inspektoren)?_c('span',{domProps:{"innerHTML":_vm._s(item.inspektoren.replace('\n','<br />'))}}):_vm._e()])]),_c('v-card-actions',{staticClass:"mb-3 pb-8"},[_c('v-btn',{staticClass:"my-4",attrs:{"fab":"","dark":"","small":"","color":"indigo","right":"","absolute":""},on:{"click":function($event){return _vm.$router.push( {
                    name: 'Zentrum', 
                    params: {
                      'uid': item.UID,
                      'item': item,
                      'user': _vm.user
                    }
                  })}}},[_c('v-icon',{attrs:{"dark":"","fab":""}},[_vm._v(" mdi-arrow-right-bold ")])],1)],1)],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }